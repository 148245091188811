import React, { useState } from 'react'
import { PageHeader } from './PageHeader'
import axios from 'axios'
import { toast, ToastContainer } from 'react-toastify';
import { PreLoader } from './PreLoader';

export const Forgot_password = () => {
  const [users, setUsers] = useState()
  const [loading, setLoading] = useState(false);
  const validate = (e) => {
    setUsers({
      ...users,
      [e.target.name]: e.target.value
    })
  }
  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    let fd = new FormData();

    fd.append("email_address", users.email);

    await axios.post(`https://perfectjodidar.com/shaadi_api/forgot_password.php`,
//    await axios.post(`http://localhost/shaadi_api/forgot_password.php`,
      fd
    ).then(response => {

      if (response.data.success == true) {
        setLoading(false);
        toast.success('Please check your mail!', { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 4000 });

        
      } else {

        setLoading(false);
        toast.error("Email address is wrong", { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 3000 });
      }

    }).catch(error => {
      setLoading(false);
      toast.error("Email address is wrong", { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 3000 });

    })


  }
  return (
    <>
      <PageHeader />
      <ToastContainer />

      {loading ?
        <PreLoader />
        : ""}
      <div id="login" style={{
        display: "table",
        position: "absolute",
        height: "100%",
        width: "100%"
      }}>

        <div className="jumbotron container" style={{ display: "table-cell", verticalAlign: "middle" }}>
          <div className="row">

            <div className="col-md-4 col-md-offset-4 text-center">
              <h1>Forgot Password</h1>
              <hr className="star-primary" />

            </div>
            <div className="col-md-4 col-md-offset-4 ">

              <div className="panel panel-login">
                <div className="panel-heading">
                  <div className="row">
                    <div className="col-xs-12">
                      {/* Login */}
                      <a href="#" className="active" id="login-form-link" ></a>
                    </div>
                  </div>
                  <hr />
                  <hr />
                </div>
                <div className="panel-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <form id="login-form" method="post" role="form" style={{ display: "block" }}>
                        <div className="form-group">
                          <input type="text" name="email" id="email" className="form-control" placeholder="Enter registered email address*" required onChange={validate} />
                        </div>
                       
                        <div className="form-group">
                          <div className="row">
                            <div className="col-sm-6 col-sm-offset-3">
                              <input type="submit" name="login-submit" id="login-submit" className="form-control btn btn-login" value="Submit" onClick={onSubmit} />
                            </div>

                          </div>
                          {/* <p style={{ color: "red" }}> {users.error ? `Sorry,There is ${users.error}` : ""}</p> */}



                        </div>

                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}
