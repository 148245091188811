import React, { useEffect, useState ,useContext} from 'react'
import FrontHeader from './FrontHeader'
import img78 from "../img/78.png";
import img79 from "../img/79.png";
import img80 from "../img/80.png";
import secureLocalStorage from 'react-secure-storage';
import { NavLink, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify';
import useRazorpay from "react-razorpay";
import axios from 'axios';
import userContext from './Usercontext';

const PreMember = () => {
	const nav = useNavigate();
	const { user, setUser } = useContext(userContext);
	const img = {
		width: "100%",
		height: "100px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center"
	}
	const font = {
		fontFamily: "'Ubuntu Condensed', sans-serif",
		fontWeight: "400"
	}
	const green = {
		color: "green"
	}

	const handlePayment1 = params => event => {
	//	console.log(params)
	}

	async function payment(params, months) {
		await axios.get(`https://perfectjodidar.com/shaadi_api/razorpay/hello.php`, { params: { amount: params } }).then(response => {
			if (response.data.success) {

				const order_id = response.data.fetchorder;
				const options = {
					key: "rzp_test_TK3JCylsfNunzR", // Enter the Key ID generated from the Dashboard
					amount: "50000",//amount: "50000", Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
					currency: "INR",
					name: "Perfect Jodidar",
					description: "Test Transaction",
					image: "../logo1.jpeg",
					order_id: order_id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
					handler: function (response) {
						// console.log("data", response)
						// alert(response.razorpay_payment_id);
						// alert(response.razorpay_order_id);
						// alert(response.razorpay_signature);
						let fd = new FormData();
						fd.append("user_id", secureLocalStorage.getItem("cust_id"));
						fd.append("email_address", secureLocalStorage.getItem("email_address"));
						// fd.append("user_id", "114");
						fd.append("razorpay_payment_id", response.razorpay_payment_id);
						fd.append("razorpay_order_id", response.razorpay_order_id);
						fd.append("razorpay_signature", response.razorpay_signature);
						fd.append("months", months);
						fd.append("amount", params);
						fd.append("payment_status", "success");

						//working code  axios.post(`https://perfectjodidar.com/shaadi_api/insert_payment.php`, fd).then(response => {

						// 	if (response.data.success) {
						// 		toast.success('Payment Successfully Done! ', { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 7000 });
						// 	}
						// }).catch((error) => {

						// 	toast.error('Some technical issue.Please save your payment_id and contact admin! ' + error.message, { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 7000 });
						// })

					},
					prefill: {
						name: "",
						email: "",
						contact: "",
					},
					notes: {
						address: "Razorpay Corporate Office",
					},
					theme: {
						color: "#1ea5a9",
					},
				};


				const rzp1 = new Razorpay(options);

				rzp1.on("payment.failed", function (response) {
					// alert(response.error.code);
					// alert(response.error.description);
					// alert(response.error.source);
					// alert(response.error.step);
					// alert(response.error.reason);
					// alert(response.error.metadata.order_id);
					// alert(response.error.metadata.payment_id);
					toast.error('Payment Fail! ' + response.error.step + ' ' + response.error.reason, { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 7000 });
					let fd = new FormData();
					fd.append("user_id", secureLocalStorage.getItem("cust_id"));
					// fd.append("user_id", "114");
					fd.append("razorpay_payment_id", response.error.metadata.payment_id);
					fd.append("razorpay_order_id", response.error.metadata.order_id);
					fd.append("razorpay_signature", '');
					fd.append("months", months);
					fd.append("amount", params);
					fd.append("payment_status", "Fail! " + response.error.step + ' ' + response.error.reason);

					axios.post(`https://perfectjodidar.com/shaadi_api/insert_payment.php`, fd).then(response => {

						if (response.datasuccess) {
							toast.success('Payment Done Successfully! ', { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 7000 });
						}
					}).catch((error) => {

						toast.error('Some technical issue.Please save your payment_id and contact admin! ' + error.message, { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 7000 });


					})
				});
				rzp1.open();

			}

		}).catch((error) => {

			toast.error('Sorry, There is some technical issue ! ' + error.message, { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 7000 });
		})
	}

	//	console.log("ddd", order_id)
	const Razorpay = useRazorpay();
	const handlePayment = params => event => {
		console.log("ssss", params);
	};
	return (
		<div>
			<FrontHeader userName={user} />
			<ToastContainer />

			<div id="posts" style={{ marginTop: "58px" }}>
				<div className="container">
					<div className="row">
						<div className="courses clearfix text-center">
							<h3>Find Your Perfect Jodidar</h3>
						</div>
						<div className="courses clearfix text-center">
						<br/><p style={{color:"red"}}>For premium membership, kindly get in touch with us.
						<br/>Contact us : +91 9104596713(WhatsApp)</p>
						</div>
						
						
						
						
						<div className="posts clearfix">
							<div className="col-sm-4">
								<div className="posts_inner">
									<img src={img78} width="100%" height="150px" />
									<div className="card-image" style={img}>

										<h1 style={font}><i className="fa fa-inr"></i> 649</h1>


									</div>
									<div className="posts_inner_1">
										<h4>3 Months </h4><hr />

										<p><i className="fa fa-check" aria-hidden="true" style={green}></i> View unlimited contact numbers</p><hr />
										<p><i className="fa fa-check" aria-hidden="true" style={green}></i> View unlimited email addresses </p>
										<h3>
											{/*Not approved <a onClick={() => payment("50000", "3")} style={{ cursor: "pointer" }} disable>Pay Now</a> */}
											
										</h3>
									</div>
								</div>
							</div>
							<div className="col-sm-4">
								<div className="posts_inner">
									<img src={img79} width="100%" height="150px" />
									<div className="card-image" style={img} >
										<h1 style={font}><i className="fa fa-inr"></i> 1149</h1>
									</div>
									<div className="posts_inner_1">
										<h4>6 Months</h4><hr />

										<p><i className="fa fa-check" aria-hidden="true" style={green}></i> View unlimited contact numbers</p><hr />
										<p><i className="fa fa-check" aria-hidden="true" style={green}></i> View unlimited email addresses </p>
										<h3>
											{/* <a onClick={() => payment("150000", "6")} style={{ cursor: "pointer" }}>Pay Now</a> */}
											</h3>
									</div>
								</div>
							</div>
							<div className="col-sm-4">
								<div className="posts_inner">
									<img src={img80} width="100%" height="150px" />
									<div className="card-image" style={img}>
										<h1 style={font}><i className="fa fa-inr"></i> 1999</h1>
									</div>
									<div className="posts_inner_1">
										<h4>12 Months</h4><hr />

										<p><i className="fa fa-check" aria-hidden="true" style={green}></i> View unlimited contact numbers</p><hr />
										<p><i className="fa fa-check" aria-hidden="true" style={green}></i> View unlimited email address </p>
										<h3>
											{/* <a onClick={() => payment("200000", "12")} style={{ cursor: "pointer" }}>Pay Now</a> */}
											</h3>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

		</div>
	)
}

export default PreMember