import React from 'react'
import { PageHeader } from './PageHeader'

export const Privacy_policy = () => {
    return (
        <>
            <PageHeader />

            <div className="container" >
                <div className="row">

                    <div className="col-md-12" style={{ marginTop: "100px" }}>
                        <h1>Privacy Policy</h1>

                        <br />

                        <h3>Information we collect</h3><br />

                        <p>
                            Registered profiles on Perfectjodidar.com on website need to fill in their basic details, personal details and contact information and any other profile related information not mentioned here during or after registration.
                        </p>

                        <br /><h3>Sharing your details</h3><br />
                        <p>
                            We will not share any of the information provided by you to any individual/Organization. The data/documents uploaded by Profiles will only be reviewed/modified/used by our team to validate the authenticity of information provided by profiles and to provide best services to registered profiles.
                        </p>
                        <br /><h3>Unsubscribe</h3><br />
                        <p>
                            We will be sorry to see you leave us before finding your life partner on Perfectjodidar.com. Perfectjodidar.com provides the option to delete your profile from the website anytime after registration. Please login and inactivate/delete your profile.
                           <br/> Notice
                           <br/>Please check our updated Privacy Policy from time-time.
                        </p>

                    </div>
                </div>
            </div>
        </>
    )
}
