import React, { useState, useEffect } from 'react'
import { Request_list } from './Request_list'
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
import { toast, ToastContainer } from 'react-toastify';

export const Request_send = () => {
    const [users, setUsers] = useState({ usersData: '', size: 0 });
    
    async function fetchData() {
      //  console.log("ddda", secureLocalStorage.getItem('cust_id'));
        await axios.get('https://perfectjodidar.com/shaadi_api/getAllSentList.php', { params: { reqId: secureLocalStorage.getItem("cust_id") } }).then(response => {
          //  console.log(response.data);
            if (response.data.success) {
                setUsers({
                    usersData: response.data.fetchusers,
                    size: response.data.fetchusers.length
                });
                //  toast.success('Request Send Successfully!', { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });
            }

        }).catch((error) => {
            console.log(error.message);
        })

    }

    useEffect(() => {
        fetchData();
    }, [])
    return (
        <>
            <div className="panel panel-default">
                <ToastContainer />
                <div className="panel-heading">Sent Request </div>
                <div className="panel-body">
                    <Request_list user_detail={users} action="sent"/>
                </div>
            </div>


        </>
    )
}
