import React, { useEffect, useState, useContext } from 'react'
import FrontHeader from './FrontHeader'
import axios from 'axios';
import userContext1 from './Login';
import { Pagination } from "react-pagination-bar"
import 'react-pagination-bar/dist/index.css'
import userContext from './Usercontext';
import { NavLink } from 'react-router-dom'
import secureLocalStorage from "react-secure-storage";
import avatarimg from "../img/avatarimg.png";
import avatarimg5 from "../img/icons8-avatar-32.png";
import { toast, ToastContainer } from 'react-toastify';
import { PreLoader } from './PreLoader';
const FirstView = () => {

  const [users, setUsers] = useState({ usersData: '', size: 0 });
  // const [req, setReq] = useState({ req_name: '', isActive: false });
  const [req, setReq] = useState([{ req_name: '', isActive: false }]);
  const [req1, setReq1] = useState([{ req_name: 'sssa', isActive: false }, { req_name: 'sss', isActive: false }]);
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [test, setTest] = useState(3);
  const pagePostsLimit = 7;
  const getCurrentYear = new Date().getFullYear();
  // const userLoginData = useContext(userContext1); 
  const { user, setUser } = useContext(userContext);
  const [loading, setLoading] = useState(false);

  async function request(user_id, email) {
    setLoading(true)
    let fd = new FormData();
    fd.append("user_id", secureLocalStorage.getItem("cust_id"));
    fd.append("req_user_id", user_id);
    fd.append("name", secureLocalStorage.getItem("cust_name"));
    fd.append("email", email);


    await axios.post(`https://perfectjodidar.com/shaadi_api/insert_request.php`,
      fd
    ).then(response => {
      if (response.data.success) {
        //        ;console.log(response.data.success)
        setLoading(false);
        toast.success('Request Send Successfully!', { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });
        if(response.data.msg){
          //toast.error("", { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 2000 });
          console.log(response.data.msg);
        }
        fetchData();

      } else {
        //setGloabalError(response.data.msg);
        //toast.error(response.data.msg, { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });
      }
    }
    ).catch((error) => {
      console.log(error);

    });
  }

  async function fetchData() {
    setLoading(true);
    await axios.get('https://perfectjodidar.com/shaadi_api/getAllUserById.php', { params: { reqId: secureLocalStorage.getItem("cust_id") } }).then(response => {

      if (response.data.success) {
       setLoading(false);
        setUsers({
          usersData: response.data.fetchusers,
          size: response.data.fetchusers.length
        });

        setTest(users.usersData.length);
        var f = users.usersData.length;
        //      console.log(users);
      } else {
        console.log("Not Getting Data");
        setUsers({ usersData: '', size: 0 });
      }
      setLoading(false);
    }).catch((error) => {
      console.log(error.message);
      //setGloabalError(error.message);
    })

  }
  function onClick1(name) {
    //console.log("dddd", name);
    // setReq({
    //     ...req,    
    //   req_name:name,
    //   isActive:true
    // });
    setReq([
      ...req,
      {
        req_name: name,
        isActive: true
      }]);
    setReq1([
      ...req1,
      {
        req_name: name,
        isActive: true
      }]);
    const found = req1.some(req_name => req_name == 'sss');
    let magenicVendors = req1.filter(vendor => vendor['req_name'] === 'sss')
    let hasMagenicVendor1 = req1.some(vendor => vendor['req_name'] === 'sss')
    //console.log("ffff", found, "dd", hasMagenicVendor1);
    setCount(
      count + 1
    )
  }


  useEffect(() => {

    fetchData();

  }, [])
  return (
    <>
      <FrontHeader userName={user} />
      <ToastContainer />

      {loading ?
        <PreLoader />
        : ""}
      {/* {test}{users.usersData.length}
      {users.usersData ? users.usersData.map((post) =>
        <div id={post.id}>{post.name}</div>
      ) : ""} */}{/*                 
      {users.usersData
              .slice((currentPage - 1) * pagePostsLimit, currentPage * pagePostsLimit)
              .map((post) => {
                <div key={post.id}>{post.name}{post}aaa</div> }) } */}
      <div id="course_main" style={{ marginTop: "58px" }}>
        {/* sss  {req1.some(vendor => vendor['req_name'] === 'sss') ? "sss" : "sssd"} */}

        {/* ddd{user[0].id} */}

        <div class="container">
          <div class="row">


            {users.usersData ? users.usersData
              .slice((currentPage - 1) * pagePostsLimit, currentPage * pagePostsLimit)
              .map((post) => {
                return (
                  <>
                    {/* <div key={post.id}>{post.name}</div> */}

                    <div class="course_main_1 clearfix" >
                      <div class="col-sm-4">
                        <div class="course_main_left card-image">
                          <a href="login.html">
                            {JSON.parse(post.profile_img)[0] ?
                              <img src={JSON.parse(post.profile_img)[0]} width="100%" style={{ maxHeight: "225px", display: "block", marginLeft: "auto", marginRight: "auto", width: "auto" }} />
                              :
                              <img src={avatarimg} width="100%" style={{ maxHeight: "225px", display: "block", marginLeft: "auto", marginRight: "auto", width: "auto" }} />
                            }
                          </a>
                        </div>
                      </div>
                      <div class="col-sm-8">
                        <div class="clearfix slider_inner course_main_right">
                          <h5><a href="login.html">{post.name}</a> </h5>
                          <p>
                            City :{post.city_name ? post.city_name : 'Not Specified'} &nbsp;
                            Education :{post.Education ? post.Education : 'Not Specified'} &nbsp;
                            Age :{post.Birth_year ? getCurrentYear - post.Birth_year : ''}
                            {/* Cast :{post.cost?post.cost:'Not Specified'} */}
                          </p>
                          <div class="slider_inner_1 clearfix">
                            <div class="col-sm-6 space_left">
                              <div class="slider_inner_1_left">
                                <h4><a style={{ cursor: "pointer" }} onClick={() => { request(post.user_id, post.email); }}>Send Request </a></h4>
                                {/* <button onClick={()=>onClick1(post.name) } 
                                style={{backgroundColor:req.isActive && req.req_name == post.name?"red":"blue"}}
                                id={post.name}>Request</button> */}

                                {/* working send request <button onClick={() => onClick1(post.name)}
                                  style={{ backgroundColor: req.some(vendor => vendor['req_name'] === post.name) ? "red" : "blue" }}
                                  id={post.name}>Send Request</button> */}

                              </div>
                            </div>
                            <div class="col-sm-6 space_left">
                              <div class="slider_inner_1_right text-right">
                                {/* <h4><a href="login.html">See Details</a></h4> */}
                                {/* <span><NavLink to={`/lookprofile?${encodeURIComponent("getdata=post.user_i")}`}>{post.user_id}jj See Details</NavLink></span> */}
                                <span><NavLink to={`/lookprofile?${encodeURIComponent("getdata=" + post.user_id)}`} target="_blank">See Details</NavLink></span>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>

                  </>
                )
              }) : "no data  "}
            <Pagination
              currentPage={currentPage}
              itemsPerPage={pagePostsLimit}
              onPageChange={(pageNumber) => setCurrentPage(pageNumber)}
              totalItems={users.usersData.length}
              pageNeighbours={2}
            />
            {/* <Pagination
        initialPage={currentPage}
        itemsPerPage={pagePostsLimit}
        onPageСhange={(pageNumber) => setCurrentPage(pageNumber)}
        totalItems={10}
        pageNeighbours={2}
        nextLabel=">"
        prevLabel='<'

      /> */}
          </div>



        </div>
      </div>
    </>
  )
}

export default FirstView