import React, { useEffect, useState } from 'react'
import { LoadData, GetUserById } from '../functions/CommonData'
import { useForm } from 'react-hook-form'
import secureLocalStorage from 'react-secure-storage'
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';

export const Personal_details = () => {
    const label = { fontSize: "16px" };
    const [loadData, setLoadData] = React.useState([]);
    const [userData, setUserData] = React.useState([]);
    // const { register, handleSubmit, defaultValues } = useForm();
    const [disable, setDisable] = useState('');
    const { register, handleSubmit, defaultValues } = useForm();
    useEffect(() => {
        LoadData().then(setLoadData);
        GetUserById().then(setUserData)
    }, [])
    const onSubmit = async (data) => {
        // setDisable("disabled")
        
        let fd = new FormData();
        fd.append("profilefor", data.profilefor==0?'':data.profilefor);
        fd.append("firstname", data.firstname);
        fd.append("lastname", data.lastname);
        fd.append("mstatus", data.mstatus==0?'':data.mstatus);
        fd.append("cont", data.cont);
        fd.append("email", data.email);
        fd.append("mother", data.mother);
        fd.append("eating", data.eating==0?'':data.eating);
        fd.append("gender", data.gender==null?'':data.gender);


        fd.append("userid", secureLocalStorage.getItem("cust_id"));

        await axios.post(`https://perfectjodidar.com/shaadi_api/update_personaldetails.php`,
            fd
        ).then(response => {
        
            if (response.data.success) {
                toast.success('Updated Successfully!', { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });

            } else {
                toast.error(response.data.msg, { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });
            }
        }
        ).catch((error) => {
            toast.error(error.msg, { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });

        });
    }
    return (
        <>
        <ToastContainer/>
            <div className="panel panel-default">
                <div className="panel-heading" >Person Details</div>
                <div className="panel-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label style={label} >Profile For:</label>
                            <select className="form-control" name="profilefor" {...register("profilefor")}/*onChange={onChangeValue}*/ >
                                <option key="0" value="0">--Select Profile For--</option>
                                {
                                    loadData.profilefor ? loadData.profilefor.map((e, key) => {
                                        return <option key={key} value={e.id} selected={userData ? e.id == userData.profile_for ? 'selected' : '' : ''}>{e.name}</option>
                                    }) : ""
                                }

                            </select>

                        </div>

                        <div className="form-group">
                            <label className={label}>First Name:*</label>
                            <input type="text" placeholder='Enter your first name' name="firstname" /*onChange={onChangeValue} */ className="form-control" defaultValue={userData?userData.first_name:''} {...register("firstname")}/*{...register("firstname", { required: true, minLength: 2 })}*/  />

                        </div>


                        <div className="form-group">
                            <label className={label}>Last Name:</label>
                            <input type="text" placeholder='Enter your last name' name="lastname" /*onChange={onChangeValue}*/ className="form-control" defaultValue={userData?userData.last_name:''} {...register("lastname")} />
                        </div>
                        <div className="form-group">
                            <label className={label}>Marital Status:</label>
                            <select className="form-control" name="mstatus" {...register("mstatus")}/*value={globalData?.status.id} onChange={onChangeValue}*/>
                                <option key="0" value="0">--Select Marital Status--</option>
                                {
                                    loadData.status ? loadData.status.map((e, key) => {
                                        return <option key={key} value={e.id} selected={userData ? e.id == userData.marital_status ? 'selected' : '' : ''}>{e.name}</option>
                                    }) : ""
                                }
                            </select>
                        </div>
                        <div className="form-group">
                            <label className={label}>Contact No:</label>
                            <div className="form-inline">
                                {/* <input type="number" className="form-control" placeholder='+91' /> */}

                                <input type="text" className="form-control" placeholder='Enter WhatsApp Number' defaultValue={userData?userData.contact_no:''} {...register("cont")} />
                            </div>

                        </div>
                        <div className="form-group">
                            <label className={label}>Email Address:</label>
                            <input type="text" className="form-control" placeholder='Enter Email address' defaultValue={userData?userData.email_address:''} {...register("email")} />
                        </div>
                        <div className="form-group">
                            <label className={label}>Mother tonque:</label>
                            <input type="text" className="form-control" placeholder='Enter Mother Tonque' defaultValue={userData?userData.mother_tonque:''} {...register("mother")}/>
                        </div>
                        <div className="form-group">
                            <label className={label}>Eating Habits:</label>
                            <select className="form-control" name="eating" id="eating" {...register("eating")}>
                                <option key="0" value="0">--Select Eating Habits--</option>
                                <option value="Non Vegitarian" key="2" selected={userData ? userData.eating_habits == "Non Vegitarian" ? 'selected' : '' : ''}>Non Vegitarian</option>
                                <option value="Eggetarian" key="3" selected={userData ? userData.eating_habits == "Eggetarian" ? 'selected' : '' : ''}>Eggetarian</option>
                            </select>
                        </div>
                        <div className="form-group">
                            <label className={label}>Gender:</label>
                            <div className="">
                                {
                                    userData ? userData.gender == "female" ? <label className="radio-inline"><input type="radio" checked={userData ? userData.gender == "female" ? 'selected' : '' : ''} name="optradio" value="female" {...register("gender", { required: true})} /> female  </label>: <label className="radio-inline"><input type="radio"  name="optradio" value="female" {...register("gender", { required: true})} /> female  </label> :''                                    
                                }
                                {
                                    userData ? userData.gender == "male" ? <label className="radio-inline"><input type="radio" checked={userData ? userData.gender == "male" ? 'selected' : '' : ''} name="optradio" value="male" {...register("gender", { required: true})} /> male  </label>: <label className="radio-inline"><input type="radio" name="optradio" value="male" {...register("gender", { required: true})} /> male  </label> :''
                                }
                                {/* <label className="radio-inline"><input type="radio" checked={userData ? userData.gender == "female" ? 'selected' : '' : ''} name="optradio"   />Female</label>                                
                                <label className="radio-inline"><input type="radio" checked={userData ? userData.gender == "male" ? 'selected' : '' : ''} name="optradio"  />Male</label> */}

                            </div>
                        </div>
                        <div className="col-md-6 col-md-offset-3" style={{ padding: "20px" }}>
                            {/* <button type="submit" class="btn btn-default btn-lg">Submit</button> */}
                            <input type="submit" name="login-submit" id="login-submit" className="form-control btn btn-reg" value="Update" disabled={disable}></input>
                        </div>

                    </form>
                </div>
            </div>
        </>
    )
}
