import React, { useEffect, useState } from 'react'
import { Number } from '../functions/GenrateNumber';
import 'bootstrap-toggle/css/bootstrap-toggle.min.css';
import 'bootstrap-toggle/js/bootstrap-toggle.js';
import { useForm } from 'react-hook-form'
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';
export const Horoscope_details = (props) => {

    const { register, handleSubmit,defaultValues } = useForm();
    
    const onSubmit = async(data) => {
        // console.log(data);
        // console.log(data.day);
        // console.log("ddd",loginDetails);
        // console.log(!data.day?loginDetails.birth_date:data.day);
        let fd = new FormData();
        // console.log(loginDetails.birth_month===null ?"fff":"null");
        fd.append("birth_day", !data.day?loginDetails.birth_date?loginDetails.birth_date:null:data.day);
        fd.append("birth_month", !data.month?loginDetails.birth_month?loginDetails.birth_month:null:data.month);
        fd.append("birth_year", !data.year?loginDetails.birth_year?loginDetails.birth_year:null:data.year);
        fd.append("hours", !data.hours?loginDetails.birth_hr?loginDetails.birth_hr:null:data.hours);
        fd.append("mins", !data.min?loginDetails.birth_min?loginDetails.birth_min:null: data.min);
        fd.append("sec", !data.sec?loginDetails.birth_sec?loginDetails.birth_sec:null:data.sec);
        fd.append("user_id", secureLocalStorage.getItem("cust_id"));
        
        await axios.post(`https://perfectjodidar.com/shaadi_api/update_user.php`,
            fd
        ).then(response => {
            // console.log(response);
            if (response.data.success) {
                toast.success('Updated Successfully!', { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });
                
            } else {
                toast.error(response.data.msg, { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });
            }
        }
        ).catch((error) => {
            console.log(error);

        });
    }
    const v1 = 19;
    
//    console.log("Login Details");
    const [loginDetails, setLoginDetails] = useState();
    const [selectedMonth, setSelectedMonth] = useState();
    const handleChange = event => {
        // console.log(event.target.value);
        setSelectedMonth(event.target.value);
      };
    //   console.log(props.users[0].id);
    //console.log(props.users.fetchusers);
    // console.log(props.users.name);

    useEffect(() => {
        fetchData();
    },[]);
    async function fetchData() {
       await axios.get('https://perfectjodidar.com/shaadi_api/getUserDetails.php', { params: { userId: secureLocalStorage.getItem("cust_id") } }).then(response => {
  //          console.log(response.data);
            // console.log(response.data.id);
            // //user_month = response.data.birth_month;
            // console.log("sss",response.data.birth_month);
            setSelectedMonth(response.data.birth_month);
            setLoginDetails(response.data);
// console.log("ddd",loginDetails);
            // setUsers({
            //   usersData: response.data.fetchusers,
            //   size: response.data.fetchusers.length
            // });
            // var f = users.usersData.length;
            // console.log(users);
        }).catch((error) => {
            console.log(error.message);
            //setGloabalError(error.message);
        })

    }

    return (

        <>
        <ToastContainer />
            <div className="panel panel-default">
            
                <div className="panel-heading">Horoscope Details</div>
                <div className="panel-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label >Birth Date:</label>
                            <div className="form-inline">

                                <select {...register("day")} name="day" id="day" className="form-control" >
                                    <option value="">--Birth Date--</option>
                                    {
                                        Number(31).map(no => <option key={no} selected={loginDetails?no == loginDetails.birth_date?'selected':'':''} value={no} >{no}</option>)
                                    }
                                </select>
                              
                                <select {...register("month")} name="month" id="month" value={selectedMonth} onChange={handleChange} className="form-control" >
                                    <option value='' >--Birth Month--</option>
                                    <option value='01' label="Jan">Jan</option>
                                    <option value='02' label="Feb">Feb</option>
                                    <option value='03' label="Mar">Mar</option>
                                    <option value='04' label="Apr">Apr</option>
                                    <option value='05' label="May">May</option>
                                    <option value='06' label="Jun">Jun</option>
                                    <option value='07' label="Jul">Jul</option>
                                    <option value='08'  label="Aug">Aug</option>
                                    <option value='09' label="Sep">Sep</option>
                                    <option value='10' label="Oct">Oct</option>
                                    <option value='11' label="Nov">Nov</option>
                                    <option value='12' label="Dec">Dec</option>
                                </select>
                                {/* selected=loginDetails?'08' == loginDetails.birth_month?'selected':'':'' */}
                                <input type="text" {...register("year")} name="year" className="form-control" defaultValue={loginDetails?loginDetails.birth_year:''}/>
                                {/* //text={props?props.users[0].id:''} selected={v1==no?'selected':''}*/}
                            </div>
                        </div>
                        <div className="form-group">
                            <label >Birth Time:</label>
                            <div className="form-inline">
                                <select name="hours" id="hours" {...register("hours")} className="form-control" default={loginDetails?loginDetails.birth_hr:''}>
                                    <option value="" >--hours--</option>
                                    <option value="0" label="0">0</option>
                                    {
                                        Number(23).map(no => <option key={no} selected={loginDetails?no == loginDetails.birth_hr?'selected':'':''} value={no}>{no}</option>)
                                    }

                                </select>
                                <select name="min" id="min" className="form-control" {...register("min")}>
                                    <option value="" >--min--</option>
                                    <option value="0" label="0">0</option>
                                    {
                                        Number(59).map(no => <option key={no} selected={loginDetails?no == loginDetails.birth_min?'selected':'':''} value={no}>{no}</option>)
                                    }

                                </select>
                                <select name="sec" id="sec" className="form-control" {...register("sec")}>
                                    <option value="" >--sec--</option>
                                    <option value="0" label="0">0</option>
                                    {
                                        Number(59).map(no => <option key={no} selected={loginDetails?no == loginDetails.birth_sec?'selected':'':''} value={no}>{no}</option>)
                                    }


                                </select>
                            </div>
                        </div>
                        
                        <div className="col-md-6 col-md-offset-3" style={{ padding: "20px" }}>
                            {/* <button type="submit" class="btn btn-default btn-lg">Submit</button> */}
                            <input type="submit" name="login-submit" id="login-submit" className="form-control btn btn-reg" value="Update"></input>
                        </div>

                    </form>
                </div>
            </div>
        </>
    )
}
