import React,{useContext} from 'react'
import FrontHeader from './FrontHeader'
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import { Navigate, useNavigate } from 'react-router-dom';
import userContext from './Usercontext';
const Delete_profile = () => {
    const nav = useNavigate();
    const { user, setUser } = useContext(userContext);
    const yes = () => {
        let fd = new FormData();
        fd.append("cust_id", secureLocalStorage.getItem("cust_id"));
        axios.post('https://perfectjodidar.com/shaadi_api/delete_profile.php', fd).then(response => {            
            if (response.data.success) {                
                toast.success('Deleted Successfully!', { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });               

                setTimeout(() => {
                    secureLocalStorage.clear();
                    nav('/login');
                }, 3000);
            } else {
                toast.error(response.data.msg, { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });
            }
        }).catch((error) => {
            console.log(error.message);            
        })

    }
    return (
        <div>
            <FrontHeader userName={user}/>
            <ToastContainer />
            <div className="container" style={{ marginTop: "50px" }}>
                <div className="row">
                    <div className="col-sm-12">
                        <div className="panel panel-default" style={{ marginTop: "40px" }}>
                            <div className="panel-heading">Delete Profile</div>
                            <div className="panel-body">
                                <div id="course_main" >
                                    <h4>  Are you sure want to delete the profile ?</h4>
                                    <div className="col-md-6" style={{ padding: "20px" }} >
                                        <div className="col-md-3">
                                            <button className="form-control btn btn-reg" onClick={yes}>Yes</button>
                                        </div>
                                        <div className="col-md-3">
                                            <button className="form-control btn btn-reg">No</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Delete_profile