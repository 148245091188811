import React ,{useEffect}from 'react'
import { NavLink ,useNavigate} from 'react-router-dom'
import secureLocalStorage from 'react-secure-storage';
import { toast, ToastContainer } from 'react-toastify';
const FrontHeader = (props) => {
    const nav = useNavigate();

    const logout = () =>{        
        secureLocalStorage.clear();
        setTimeout(() => {
            nav('/');
        }, 500);
    }

    useEffect(() => {
        if(!secureLocalStorage.getItem("cust_id")){
            toast.success('Please Do Login!', { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });               
            setTimeout(() => {
                nav('/login');
                console.log("dddd call");
            }, 1500);
        }
    }, []);

    return (
        <div id="header">
            <ToastContainer />
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <nav className="navbar navbar-default navbar-fixed-top navbar-shrink" role=" navigation">
                            <div className="navbar-header">
                                <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                    <span className="icon-bar"></span>
                                </button>
                                <a className="navbar-brand" href="index.html"><span style={{ background: "rgba(27, 180, 185, 0.90)", padding: "10px" }}>Perfect</span><span style={{ background: "#ea9215", padding: "10px" }}> Jodidar</span> </a>
                            </div>
                            <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                                <ul className="nav navbar-nav navbar-right">
                                <li><NavLink to="/front"> Hi,<span style={{textTransform:"uppercase"}}> {secureLocalStorage.getItem("cust_name")}</span></NavLink></li>
                                    <li><NavLink to="/front"> <i class="fa fa-fw fa-home"></i> Home</NavLink></li>
                                    <li><NavLink to="/request/search"> <i className="fa fa-fw fa-search"></i>Search</NavLink></li>
                                    {/* <li><a href="detail.html"><i class="fa fa-rocket" aria-hidden="true"></i> Requested</a></li> */}
                                    <li><NavLink to="/request/req"> <i class="fa fa-rocket"></i> Requested</NavLink></li>
                                    {/* <li><a href="blog.html"><i className="fa fa-check" aria-hidden="true"></i>Accepted</a></li> */}
                                    <li><NavLink to="/request/accept"> <i class="fa fa-check"></i> Accepted</NavLink></li>
                                    {/* <li><a href="detail.html"><i className="fa fa-ban" aria-hidden="true"></i>Rejected</a></li> */}
                                    <li><NavLink to="/request/reject"> <i class="fa fa-ban"></i> Rejected</NavLink></li>
                                    {/* <li><NavLink to="/request/reject"> <i class="fa fa-ban"></i> Sent Request</NavLink></li>
                                    <li><NavLink to="/request/reject"> <i class="fa fa-ban"></i> Your Accepted Request</NavLink></li> */}
                                    {/* <li><a href="detail.html">Logout</a></li> */}
                                    {/* <li><a href="index.html"><i className="fa fa-fw fa-user"></i>Profile</a></li> */}

                                    {/* <li >
                                        <a href="#" className="dropdown-toggle profile-image" data-toggle="dropdown">
                                            <img src="https://s3.eu-central-1.amazonaws.com/bootstrapbaymisc/blog/24_days_bootstrap/logo_white.png" className="img-circle special-img" /> Profile <b class="caret"></b></a> */}
                                    {/* <ul className="dropdown-menu">
                                            <li><a href="#"><i class="fa fa-cog"></i> Account</a></li>
                                            <li className="divider"></li>
                                            <li><a href="#"><i className="fa fa-sign-out"></i> Sign-out</a></li>
                                        </ul> */}
                                    {/* </li> */}

                                    <li className="dropdown">

                                        <a className="dropdown-toggle" data-toggle="dropdown" href="blog.html" aria-expanded="false">
                                            <i className="fa fa-fw fa-user"></i>Profile

                                            <span className="caret"></span></a>
                                        <ul className="dropdown-menu box_inner">
                                        <li>
                                        {/* {props.userName} */}
                                            <NavLink to="/front">Hi, {secureLocalStorage.getItem("cust_name")}</NavLink>
                                            </li>
                                            <li>
                                            <NavLink to="/front">Home</NavLink>
                                            </li>
                                            <li>
                                            <NavLink to="/editprofile/perosnaldetails"> Edit</NavLink>

                                                {/* <a href="detail.html">Edit</a> */}
                                            </li>
                                            <li>
                                            <NavLink to="/editprofile/photo"> Photo</NavLink>

                                                {/* <a href="detail.html">Edit</a> */}
                                            </li>
                                            <li>
                                            <NavLink to="/profile/delete"> Delete</NavLink>

                                                {/* <a href="detail.html">Edit</a> */}
                                            </li>
                                            <li>
                                            <NavLink to="/profile/premember"> Premium Membership</NavLink>

                                            </li>
                                            <li style={{cursor:"pointer"}}>
                                            {/* <NavLink to="/profile/delete"> Logout</NavLink> */}
                                                <a onClick={logout}>Logout</a>
                                            </li>
                                            {/* <li><a href="team.html">Our Team</a></li> */}
                                        </ul>
                                    </li>


                                </ul>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FrontHeader