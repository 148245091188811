import React from 'react';
import ReactDOM from 'react-dom/client';
import { Shaadi } from './Shaadi';
import "./css/bootstrap.min.css";
import "./css/style.css";
import "./css/element.css";
import "./css/font-awesome.min.css";
import 'react-toastify/dist/ReactToastify.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Shaadi/>
  
);
