import React, { useEffect,useState } from 'react'
import { LoadData, GetUserById } from '../functions/CommonData'
import { useForm } from 'react-hook-form'
import axios from 'axios'
import secureLocalStorage from 'react-secure-storage'
import { toast, ToastContainer } from 'react-toastify';
export const Community_details = (props) => {
    const [loadData, setLoadData] = React.useState([]);
    const [userData, setUserData] = React.useState([]);
    const { register, handleSubmit, defaultValues } = useForm();
    const [disable, setDisable] = useState('');

    const onSubmit = async (data) => {
        setDisable("disabled")
        // console.log(data);
        let fd = new FormData();
        fd.append("rel", data.rel);
        fd.append("cast", data.cast);
        fd.append("userid", secureLocalStorage.getItem("cust_id"));

        await axios.post(`https://perfectjodidar.com/shaadi_api/update_community.php`,
            fd
        ).then(response => {
            // console.log(response);
            if (response.data.success) {
                toast.success('Updated Successfully!', { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });

            } else {
                toast.error(response.data.msg, { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });
            }
        }
        ).catch((error) => {
            toast.error(error.msg, { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });

        });
    }


    useEffect(() => {
        LoadData().then(setLoadData);
        GetUserById().then(setUserData)
    }, [])

    return (
        <>
            {/* {props.loginUsername?props.loginUsername:'kk'} */}
            {/* dd {props.users} */}
            <div className="panel panel-default">

                <div className="panel-heading">Community Details</div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="panel-body">
                        <div className="form-group">
                            <label >Religion For:</label>
                            <select className="form-control" {...register("rel")} name="rel">
                                <option>--Select Religion--</option>
                                {
                                    loadData.rel ? loadData.rel.map((e, key) => {
                                        return <option key={key} value={e.id} selected={userData ? e.id == userData.religion ? 'selected' : '' : ''}>{e.name}</option>
                                    }) : ""
                                }

                            </select>
                        </div>
                        <div className="form-group">
                            <label >Cast:</label>
                            <input type="text" className="form-control" name="cast" defaultValue={userData ? userData.cast : ''} {...register("cast")}/>
                        </div>
                        <div className="col-md-6 col-md-offset-3" style={{ padding: "20px" }}>
                            {/* <button type="submit" class="btn btn-default btn-lg">Submit</button> */}
                            <input type="submit" name="login-submit" id="login-submit" className="form-control btn btn-reg" value="Update" disabled={disable}></input>
                        </div>

                    </div>
                </form>
            </div>
        </>
    )
}
