import React, { useState, useEffect } from 'react'

import { Header } from './Header/Header';
import { Register } from './components/Register';
import { Request } from './components/Request';
import { Regitest } from './components/Regitest';
import Test from './components/Test';
import Login from './components/Login';
import { Terms_and_condition } from './components/Terms_and_condition';
import { Privacy_policy } from './components/Privacy_policy';
import { About_us } from './components/About_us';
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import FirstView from './components/FirstView';
import { EditProfile } from './components/EditProfile';
import { EditProfile_details } from './components/EditProfile_details';
import { Horoscope_details } from './components/Horoscope_details';
import { Look_profile } from './components/Look_profile';
import userContext from './components/Usercontext';
import Delete_profile from './components/Delete_profile';
import PreMember from './components/PreMember';
import secureLocalStorage from 'react-secure-storage';
import { Forgot_password } from './components/Forgot_password';
import { toast, ToastContainer } from 'react-toastify';
export const Shaadi = () => {
  const [user, setUser] = useState("username");
  

  useEffect(() => {
    // window.location.pathname != "/login" && 
//     if (!secureLocalStorage.getItem("cust_id")) {
//  //     toast.success('Please Do Login!', { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });
//     } 
//console.log("test",secureLocalStorage.getItem("cust_id"));
  }, []);
 
  return (
    <BrowserRouter>
      <userContext.Provider value={{ user, setUser }}>
        <Routes >
          <Route path="/" exact element={<Header />} />
          {/* <Route path="/register" exact element={!secureLocalStorage.getItem("cust_id") ? <Register />: <Navigate to="/front"/>} /> */}
          <Route path="/register" exact element={ <Register />} />
          <Route path="/Terms_and_condition" exact element={<Terms_and_condition />} />
          <Route path="/privacy" exact element={<Privacy_policy />} />
          <Route path="/aboutus" exact element={<About_us />} />
          {/* <userContext.Provider value={{user,setUser}}>   */}
         
          {/* <Route path="/login" exact element={!secureLocalStorage.getItem("cust_id") ? <Login />:<Navigate to="/front"/>}  /> */}
          <Route path="/login" exact element={<Login />}  />
          <Route path="/front" exact element={<FirstView />} />
         
          {/* </userContext.Provider> */}


          <Route path="/horoscope_edit" element={<Horoscope_details />} />
          <Route path="/editprofile/:id" exact element={<EditProfile_details />} />
          <Route path="/lookprofile" exact element={<Look_profile />} />
          <Route path="/profile/delete" exact element={<Delete_profile />} />
          {
            secureLocalStorage.getItem("cust_id") ?
              <Route path="/profile/premember" exact element={<PreMember />} />
              : <Route path="*" element={<Navigate to="/login" replace />} />
          }
         <Route path="/forgotpassword" exact element={<Forgot_password />} />
          {/* {
          secureLocalStorage.getItem("cust_id") ? */}
          <Route path="/request/:request" exact element={<Request />} />
          {/* //  :<Route path="*" element={<Navigate to="/login" replace/>} />
        // } */}




        </Routes>
      </userContext.Provider>
    </BrowserRouter>

  )
}
