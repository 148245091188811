import React,{useContext} from 'react'
import FrontHeader from './FrontHeader'
import { NavLink, useNavigate } from 'react-router-dom';
import { Horoscope_details } from './Horoscope_details';
import { useLocation } from 'react-router-dom';
import { Request_accept } from './Request_accept';
import { Request_reject } from './Request_reject';
import { Request_req } from './Request_req';
import { Request_search } from './Request_search';
import { Request_send } from './Request_send';
import secureLocalStorage from "react-secure-storage";
import userContext from './Usercontext';

export const Request = () => {
    const location = useLocation()
    const nav = useNavigate();
    const { user, setUser } = useContext(userContext);
    //console.log(user);
    // console.log(location.pathname);
    // userName={user}
    //const history = useHistory()
    //history.location.pathname
    return (
        <>
            <FrontHeader userName={user}/>
            {/* <ToastContainer /> */}
            <div id="blog">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 blog">
                            <div className="col-sm-3">
                                <div className="blog_right clearfix">
                                    {/* <div className="blog_right_inner clearfix">
                                        <h3>Search</h3>
                                        <input type="text" className="form-control" placeholder="Search...">
                                    </div> */}
                                    <div className="blog_right_inner_1 clearfix">
                                        <h4><i className="fa fa-list"></i>PROFILE</h4>

                                        <p><NavLink to="/request/accept" style={({ isActive }) => (
                                            isActive ? { color: '#f5b120' } : {})}><i className="fa fa-caret-right"></i>Accepted Request</NavLink></p>
                                        <p><NavLink to="/request/reject" style={({ isActive }) => (
                                            isActive ? { color: '#f5b120' } : {})}><i className="fa fa-caret-right"></i>Rejected Request</NavLink></p>
                                        <p><NavLink to="/request/req" style={({ isActive }) => (
                                            isActive ? { color: '#f5b120' } : {})}><i className="fa fa-caret-right"></i>Requested Request</NavLink></p>
                                        <p><NavLink to="/request/sent" style={({ isActive }) => (
                                            isActive ? { color: '#f5b120' } : {})}><i className="fa fa-caret-right"></i>Sent Request</NavLink></p>
                                        {/* <p><NavLink to="/request/search" style={({ isActive }) => (
                                            isActive ? { color: '#f5b120' } : {})}><i className="fa fa-caret-right"></i>Sent Accepted Request</NavLink></p>
                                        <p><NavLink to="/request/search" style={({ isActive }) => (
                                            isActive ? { color: '#f5b120' } : {})}><i className="fa fa-caret-right"></i>Sent Rejected Request</NavLink></p> */}
                                        <p><NavLink to="/request/search" style={({ isActive }) => (
                                            isActive ? { color: '#f5b120' } : {})}><i className="fa fa-caret-right"></i>Search</NavLink></p>
                                    </div>

                                </div>
                            </div>

                            <div className="col-sm-9">
                                <div className="blog_left clearfix" style={{ marginTop: "40px" }}>
                                    {/* <div className="clearfix"><a href="#"><img src="img/60.jpg" width="100%"/></a></div>
                                    <h3><a href="#">Sed cursus ante dapibus diam</a></h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio.Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum.Praesent mauris. Fusce nec tellus sed augue semper porta. Mauris massa. Vestibulum lacinia arcu eget nulla.className aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Vestibulum lacinia arcu eget nulla.className aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitursodales ligula in libero.</p>
                                    <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio.Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum.<a href="#">Praesent mauris</a>. Fusce nec tellus sed augue semper porta. Mauris massa. Vestibulum <a href="#">lacinia arcu</a> eget nulla.className aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitursodales ligula in libero.Sed dignissim lacinia nunc.</p>
                                    <p>  Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum.<a href="#">Praesent mauris</a>. Fusce nec tellus sed augue semper porta. Mauris massa. Vestibulum <a href="#">lacinia arcu</a> eget nulla.className aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitursodales ligula in libero.Sed dignissim lacinia nunc. Vestibulum <a href="#">lacinia arcu</a> eget nulla.className aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitursodales ligula in libero.Sed dignissim lacinia nunc</p>
                                    <div className="blog_left_inner clearfix">
                                        <a href="#" data-toggle="tooltip" title="Writer"><i className="fa fa-user"></i>NEW USER</a>
                                        <a href="#" data-toggle="tooltip" title="Released Date"><i className="fa fa-calendar"></i> 17 MAY 2017</a>
                                    </div> */}

                                    {
                                        location.pathname === "/request/accept" ?
                                            <Request_accept />
                                            : ""
                                    }
                                    {
                                        location.pathname === "/request/reject" ?
                                            <Request_reject />
                                            : ""
                                    }
                                    {
                                        location.pathname === "/request/req" ?
                                            <Request_req />
                                            : ""
                                    }
                                    {
                                        location.pathname === "/request/search" ?
                                            <Request_search />
                                            : ""
                                    }
                                    {
                                        location.pathname === "/request/sent" ?
                                            <Request_send />
                                            : ""
                                    }

                                    {/* {location.pathname}
                                    hetali{process.env.REACT_APP_API_LOCATION} */}

                                </div>


                            </div>

                        </div>
                    </div>
                </div>
            </div>



        </>
    )
}
