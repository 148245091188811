import React from 'react'
import { PageHeader } from './PageHeader'

export const About_us = () => {
    return (
        <>
            <PageHeader />

            <div className="container" >
                <div className="row">

                    <div className="col-md-12" style={{ marginTop: "100px" }}>
                        <h1>About Us</h1>
                        <br /><p>Perfectjodidar.com aims to provide top marital services to those looking to be married at affordable prices.The Site has been developed, promoted, and managed by us,Parle Point in Surat, Gujarat, India | Phone : +91 9104596713.</p>
                        <br /><p>A matchmaking service called Perfectjodidar is dedicated to assisting both men and women in finding their true love. You have found the ideal wedding or matrimony platform if you are looking for a bride or groom in a particular community. This is the only place you need to look for Indian brides and grooms online. For those looking for a spouse who have particular criteria in mind for their life partner, Perfectjodidar is a fantastic resource. You can look for a bride and groom based on preferences like age, caste, and religion. To access an unlimited number of Indian brides and grooms, register yourself for free.
                        </p>
                        <br />   <h2>Introduction</h2>
                        <br /><p>Matrimonial sites serve as platforms designed to facilitate successful relationships and marriages by matching potential partners. In India and among Indians residing abroad, these sites have gained popularity as an alternative to traditional matchmakers. Matchmaking, in essence, involves the process of pairing two or more individuals together for the purpose of marriage. Matrimonial websites have emerged as the modern-day matchmakers, particularly in India, where they have rapidly replaced the age-old tradition of parents arranging marriages for their children.</p><br />
                        {/* <p>With their proven track record of successful matches, these sites have gained widespread popularity among the Indian population. They have played a significant role in revolutionizing the way Indians find their life partners by providing reliable and secure matrimony services. These websites offer a wide range of choices and options, ensuring that individuals have access to various profiles to select their ideal life partner successfully.
                        </p> 
                        <br />*/}
                        <br />   <h2>Why choosing Perfectjodidar matrimony?</h2><br />
                        <p>PerfectJodidar has emerged as a leading matrimony website in India, showcasing the simplicity and joy of finding an ideal life partner. It has successfully distinguished itself from other matrimonial platforms through several distinctive features:</p><br />
                        <p>1. User-Friendly Interface: PerfectJodidar boasts the most user-friendly website design, making the registration process effortless with its Single Page Registration. This streamlined approach ensures a seamless experience for users.</p><br />
                        <p>2. Comprehensive Profile Categories: The platform stands out by offering extensive categories for groom and bride profiles. These categories include parameters such as caste, mother tongue, divorcees, and widows, allowing users to refine their search and find suitable matches that align with their preferences.
                        </p><br />
                        <p>3. Emphasis on Compatibility: PerfectJodidar places a strong emphasis on compatibility, recognizing the significance of a successful and fulfilling partnership. By providing detailed profiles and comprehensive search filters, the platform enables users to find compatible matches that align with their values, interests, and life goals.
                        </p><br />
                        <p>4. Inclusivity and Diversity: The platform embraces inclusivity by catering to individuals from various backgrounds and circumstances. Whether someone is divorced, a widow, or seeks a partner from a specific caste or mother tongue, PerfectJodidar ensures that there are suitable options available for every individual's unique requirements.
                        </p><br />
                        <p>PerfectJodidar's commitment to user-friendliness, comprehensive profile categories, and inclusivity sets it apart from other matrimonial websites. It aims to provide a seamless and enjoyable experience for users while facilitating successful and meaningful connections between potential life partners.
                        </p><br />
                        <h2>Registration is Free & Membership Plans Affordable</h2><br />
                        <p>PerfectJodidar undoubtedly stands out as the most cost-effective matrimonial site in India. It offers free registration for both brides and grooms, allowing individuals to create their profiles without any financial obligations. To facilitate communication and access contact information, a nominal fee is required, enabling users to connect and establish contact with potential matches.</p><br />
                        <p>PerfectJodidar's economical approach ensures that individuals can explore and engage with other profiles on the platform without incurring a significant financial burden. By paying a small fee, users gain the ability to view and access each other's contact details, making it easier to initiate conversations and take the next steps towards building a meaningful connection.</p><br />
                        <p>This cost-effective model of PerfectJodidar makes it accessible to a wide range of individuals, providing them with an affordable option to find their life partner. The platform focuses on balancing affordability with the essential features required for successful matchmaking, creating a valuable and budget-friendly experience for its users.
                        </p><br />
                        <h2>Conclusion</h2><br/>
                        <p>For a lot of people looking for marriage, matrimonial websites have been extremely helpful in locating life partners. It has become less difficult and stressful to find the ideal mate. Thanks to perfectjodidar, the hunt for the ideal match is now hinge-free. Have them visit https://www.perfectjodidar.com/ to see which profiles best suit their requirements in a partner if you or someone you know is planning to be married soon. RECOMMENDED STRONGLY!
                        </p>
                        <br />
                    </div>
                </div>
            </div>
        </>
    )
}
