import React, { useState } from 'react'
import { Pagination } from "react-pagination-bar"
import 'react-pagination-bar/dist/index.css'
import secureLocalStorage from "react-secure-storage";
import axios from 'axios';
import { NavLink } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify';
import avatarimg from "../img/avatarimg.png";

export const Request_list = (props) => {
  const [currentPage, setCurrentPage] = useState(1);

  const [ids, setids] = useState([]);
  const [idsr, setidsr] = useState([]);
  const pagePostsLimit = 7;
  const getCurrentYear = new Date().getFullYear();

  async function request(user_id, action, email) {
    let fd = new FormData();
    fd.append("user_id", secureLocalStorage.getItem("cust_id"));
    fd.append("user_accept_id", user_id);
    fd.append("action", action);
    fd.append("email_address", email);
    fd.append("name", secureLocalStorage.getItem("cust_name"));
    if (action == "accept") {
      setids((ids) => [...ids, user_id]);
    }
    if (action == "reject") {
      setidsr((idsr) => [...idsr, user_id]);
    }
    await axios.post(`https://perfectjodidar.com/shaadi_api/insert_accept.php`,
      fd
    ).then(response => {
      if (response.data.success) {
        if (action == "accept") {
          toast.success('Request Accepted Successfully!', { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });
        }
        if (action == "reject") {
          toast.success('Request Rejected Successfully!', { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });
        }
      } else {
        toast.error(response.data.msg, { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });
      }
    }
    ).catch((error) => {
      console.log(error);
      toast.error(error, { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });

    });
  }
  async function reject(user_id, action) {
    let fd = new FormData();
    fd.append("user_id", secureLocalStorage.getItem("cust_id"));
    fd.append("user_reject_id", user_id);
    fd.append("action", action);

    await axios.post(`https://perfectjodidar.com/shaadi_api/insert_reject.php`,
      fd
    ).then(response => {
      if (response.data.success) {
        toast.success('Request Rejected Successfully!', { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });
      } else {
        toast.error(response.data.msg, { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });
      }
    }
    ).catch((error) => {
      console.log(error);
      toast.error(error, { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });

    });
  }
  return (
    <>
      <div class="row">

        {/* <div key={post.id}>{post.name}</div> */}
        {
          props.user_detail.usersData ? props.user_detail.usersData
            .slice((currentPage - 1) * pagePostsLimit, currentPage * pagePostsLimit)
            .map((post) => {
              return (

                <div class="course_main_1 clearfix" >
                  <div class="col-sm-4">
                    <div class="course_main_left card-image">
                      {/* <a href="login.html">
                        <img src={JSON.parse(post.profile_img)[0]} width="100%" style={{ maxHeight: "225px", display: "block", marginLeft: "auto", marginRight: "auto", width: "auto" }} alt=" no image"/>
                      </a> */}
                      {JSON.parse(post.profile_img)[0] ?
                              <img src={JSON.parse(post.profile_img)[0]} width="100%" style={{ maxHeight: "225px", display: "block", marginLeft: "auto", marginRight: "auto", width: "auto" }} />
                              :
                              <img src={avatarimg} width="100%" style={{ maxHeight: "225px", display: "block", marginLeft: "auto", marginRight: "auto", width: "auto" }} />
                            }
                    </div>
                  </div>
                  <div class="col-sm-8">
                    <div class="clearfix slider_inner course_main_right">
                    {/* <p style={{color:"red"}}></p> */}
                      <h5>{post.name} <span style={{color:"red"}}>{post.profile_del==1?  '[deleted profile]':''} </span></h5>
                      <p>Education:{post.Education} <br />
                        Age : {(new Date().getFullYear()) - post.Birth_year}<br />
                        Cast : {post.cast ? post.cast : 'Not Specified'}
                      </p>
                      <div class="slider_inner_1 clearfix">
                        <div class="col-sm-6 space_left">
                          {props.action == "request" ?

                            <div class="slider_inner_1_left" >
                              <h4>
                                {/* <NavLink to={`/lookprofile?${encodeURIComponent("getdata=" + post.user_id)}`}>See Details</NavLink>
                                &nbsp; */}

                                <NavLink to={`/lookprofile?${encodeURIComponent("getdata=" + post.user_id)}`} target="_blank">See&nbsp;Details</NavLink>&nbsp;
                                <a style={{ pointerEvents: ids.includes(post.user_id) ? "none" : "", cursor: "pointer" }} onClick={() => { request(post.user_id, "accept",post.email_address); }}>{ids.includes(post.user_id) ? 'Accepted' : 'Accept'}</a>&nbsp;
                                <a style={{ pointerEvents: idsr.includes(post.user_id) ? "none" : "", cursor: "pointer" }} onClick={() => { request(post.user_id, "reject",post.email_address); }}>{idsr.includes(post.user_id) ? 'Rejected' : 'Reject'}</a>

                              </h4>
                            </div>
                            : ""}

                          {props.action == "accept" ?
                            <div class="slider_inner_1_left" >
                              <h4>
                                {/* <a style={{ cursor: "pointer" }}>See Details</a> */}
                                <NavLink to={`/lookprofile?${encodeURIComponent("getdata=" + post.user_id)}`} target="_blank">See Details</NavLink>
                                &nbsp;
                                <a style={{ cursor: "pointer" }} onClick={() => { reject(post.user_id, "reject"); }}>Reject</a>

                                {/* <span></span> */}
                              </h4>
                            </div>
                            : ""}

                          {props.action == "sent" ?
                            <div class="slider_inner_1_left" >
                              <h4>
                                {/* <a style={{ cursor: "pointer" }}>See Details</a> */}
                                <NavLink to={`/lookprofile?${encodeURIComponent("getdata=" + post.user_id)}`} target="_blank">See Details</NavLink>
                                &nbsp;
                                {/* <a style={{ cursor: "pointer" }} onClick={() => { reject(post.user_id, "reject"); }}>Reject</a> */}

                                {/* <span></span> */}
                              </h4>
                            </div>
                            : ""}
                        </div>
                        <div class="col-sm-6 space_left">
                          <div class="slider_inner_1_right text-right">
                            {/* <h4><a href="login.html">See Details</a></h4>
                            <span>Accept Request</span>
                            <button                                
                            <span></span> */}
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              )
            }) : "No Data"
        }
        <Pagination
          currentPage={currentPage}
          itemsPerPage={pagePostsLimit}
          onPageChange={(pageNumber) => setCurrentPage(pageNumber)}
          totalItems={props.user_detail.usersData.length}
          pageNeighbours={2}
        />
      </div>

    </>
  )
}
