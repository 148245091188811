import React, { useState, useContext, useEffect } from 'react'
import { PageHeader } from './PageHeader'
import axios from 'axios'
import { Navigate, useNavigate } from 'react-router-dom';
import { PreLoader } from './PreLoader';
import { toast, ToastContainer } from 'react-toastify';
import userContext from './Usercontext';
import secureLocalStorage from "react-secure-storage";
import { NavLink } from 'react-router-dom'


const Login = () => {

  const { user, setUser } = useContext(userContext);

  const nav = useNavigate();
  const [users, setUsers] = useState({
    username: '',
    password: '',
    error: '',
    loading: '',
  })

  useEffect(() => {
    if (secureLocalStorage.getItem("cust_id")) {   
      nav("/front");
    } 
  })
  const validate = (e) => {
    setUsers({
      ...users,
      [e.target.name]: e.target.value
    })
  }
  const onSubmit = async (e) => {
    e.preventDefault();
    setUsers({
      ...users,
      loading: true,
      error: ''
    })
    let fd = new FormData();
    fd.append("user_name", users.username);
    fd.append("password", users.password);
    await axios.post(`https://perfectjodidar.com/shaadi_api/login_validate.php`,
      fd
    ).then(response => {

      if (response.data.success == true) {
        // setUser(response.data.fetchusers);
        setUser(response.data.fetchusers[0].name);

        secureLocalStorage.setItem('cust_id', response.data.fetchusers[0].user_id);
        secureLocalStorage.setItem('cust_name', response.data.fetchusers[0].name);
        secureLocalStorage.setItem('email_address', response.data.fetchusers[0].email_address);
        

        nav('/front');
      } else {
        setUsers({
          ...users,
          loading: false,
          error: 'Username & Password is wrong'
        });
        toast.error("Username & Password is wrong", { hideProgressBar: true, progress: undefined, position: "top-center" });
      }

    }).catch(error => {
      setUsers({
        ...users,
        loading: false,
        error: error.message
      })
      toast.error(error.message, { hideProgressBar: true, progress: undefined, position: "top-center" });

    })


  }

  return (
    <>
      <PageHeader />

      <div>

        <ToastContainer />

      </div>

      {users.loading ?
        <PreLoader />
        : ""}

      <div id="login" style={{
        display: "table",
        position: "absolute",
        height: "100%",
        width: "100%"
      }}>

        <div className="jumbotron container" style={{ display: "table-cell", verticalAlign: "middle" }}>
          <div className="row">

            <div className="col-md-4 col-md-offset-4 text-center">
              <h1>Login</h1>
              <hr className="star-primary" />

            </div>
            <div className="col-md-4 col-md-offset-4 ">

              <div className="panel panel-login">
                <div className="panel-heading">
                  <div className="row">
                    <div className="col-xs-12">
                      {/* Login */}
                      <a href="#" className="active" id="login-form-link" ></a>
                    </div>
                  </div>
                  <hr />
                  <hr />
                </div>
                <div className="panel-body">
                  <div className="row">
                    <div className="col-lg-12">
                      <form id="login-form" method="post" role="form" style={{ display: "block" }}>
                        <div className="form-group">
                          <input type="text" name="username" id="username" className="form-control" placeholder="Username" onChange={validate} required />
                        </div>
                        <div className="form-group">
                          <input type="password" name="password" id="password" className="form-control" placeholder="Password" onChange={validate} />
                        </div>
                        {/* <div className="form-group text-center">
                          <input type="checkbox" tabindex="3" className="" name="remember" id="remember">
                            <label for="remember"> Remember Me</label>
                        </div> */}
                        <div className="form-group">
                          <div className="row">
                            <div className="col-sm-6 col-sm-offset-3">
                              <input type="submit" name="login-submit" id="login-submit" className="form-control btn btn-login" value="Log In" onClick={onSubmit} />
                            </div>

                          </div>
                          <p style={{ color: "red" }}> {users.error ? `Sorry,There is ${users.error}` : ""}</p>



                        </div>

                        <div className="form-group">
                          <div className="row">
                            <div className="col-lg-12">
                              <div className="text-center">
                                {/* <a href="#" className="forgot-password">Forgot Password</a> */}
                                <NavLink to='/forgotpassword' target="_blank" className="forgot-password">Forgot Password</NavLink>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Login;