import React, { useEffect, useState } from 'react'
import { Data, LoadData } from '../functions/CommonData'
import { searchNumber } from '../functions/GenrateNumber';
import axios from 'axios';
import { useForm } from 'react-hook-form'
import { toast, ToastContainer } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';
import { NavLink } from 'react-router-dom'
import avatarimg from "../img/avatarimg.png";
import { Pagination } from "react-pagination-bar"
export const Request_search = () => {

    const [loadData, setLoadData] = React.useState([]);
    // const { register, handleSubmit, defaultValues, formState: { errors }  } = useForm();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [users, setUsers] = useState({ usersData: '', size: 0 });
    const pagePostsLimit = 10;
    const [count, setCount] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const getCurrentYear = new Date().getFullYear();
    const [ids, setids] = useState([]);

    useEffect(() => {
        LoadData().then(setLoadData);
    }, [])
    const onSubmit = async (data) => {
        // console.log(data);
        let fd = new FormData();
        fd.append("to_age", data.toage);
        fd.append("from_age", data.fromage);
        fd.append("cust_id", secureLocalStorage.getItem("cust_id"));
        fd.append("rel", data.rel);
        fd.append("stat", data.sta);

        await axios.post(`https://perfectjodidar.com/shaadi_api/searchList.php`,
            fd
        ).then(response => {
            // console.log(response);
            if (response.data.success) {
                setUsers({
                    usersData: response.data.fetchusers,
                    size: response.data.fetchusers.length
                });
                //toast.success('Updated Successfully!', { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });

            } else {
                toast.error(response.data.msg, { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });
            }
        }
        ).catch((error) => {
            console.log(error);

        });
    }

    async function request(user_id) {

        let fd = new FormData();
        fd.append("user_id", secureLocalStorage.getItem("cust_id"));
        fd.append("req_user_id", user_id);
        setids((ids) => [...ids, user_id]);

        await axios.post(`https://perfectjodidar.com/shaadi_api/insert_request.php`,
            fd
        ).then(response => {
            if (response.data.success) {
                //;console.log(response.data.success)
                toast.success('Request Send Successfully!', { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });

                //fetchData();

            } else {
                //setGloabalError(response.data.msg);
                //toast.error(response.data.msg, { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });
            }
        }
        ).catch((error) => {
            console.log(error);

        });
    }
    return (
        <>
            <div className="panel panel-default">

                <div className="panel-heading">Search </div>
                <div className="panel-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label >Religion For:</label>
                            <div className="form-inline">

                                <select className="form-control" {...register("toage", { required: true })}>


                                    <option value="">--Age--</option>
                                    {
                                        searchNumber(71).map(no => <option key={no} value={no}>{no}</option>)
                                    }

                                </select>
                                &nbsp;To&nbsp;
                                <select className="form-control"  {...register("fromage", { required: true })}>
                                    <option value="">--Age--</option>
                                    {
                                        searchNumber(71).map(no => <option key={no} value={no}>{no}</option>)
                                    }

                                </select>
                                <p className="error" style={{ color: "red" }}>{errors.toage && errors.toage.type === "required" && <p className="error" style={{ color: "red", fontSize: "14px" }}>Please Enter Age</p>}</p>
                                <p className="error" style={{ color: "red" }}>{errors.fromage && errors.fromage.type === "required" && <p className="error" style={{ color: "red", fontSize: "14px" }}>Please Enter Age</p>}</p>
                            </div>

                            {/* kk{values12} */}
                        </div>
                        <div className="form-group">
                            <label >Religion For:</label>
                            <select className="form-control" {...register("rel")}>
                                <option value="">--Select Religion--</option>
                                {
                                    loadData.rel ? loadData.rel.map((e, key) => {
                                        return <option key={key} value={e.id}>{e.name}</option>
                                    }) : ""
                                }

                            </select>
                            {/* kk{values12} */}
                        </div>
                        <div className="form-group">
                            <label className="">Marital Status:</label>
                            <select className="form-control" name="mstatus" {...register("sta")}>
                                <option value="">--Select Marital Status--</option>

                                {
                                    loadData.status ? loadData.status.map((e, key) => {
                                        return <option key={key} value={e.id}>{e.name}</option>;
                                    }) : ""
                                }
                            </select>
                        </div>
                        <div className="col-md-6 col-md-offset-3" style={{ padding: "20px" }}>
                            {/* <button type="submit" class="btn btn-default btn-lg">Submit</button> */}
                            <input type="submit" name="login-submit" id="login-submit" className="form-control btn btn-reg" value="Search"></input>
                        </div>
                    </form>
                </div>


                {/* list                     */}

                {/* <div class="container1"> */}
                {/* <div class="row"> */}


                {users.usersData ? users.usersData
                    .slice((currentPage - 1) * pagePostsLimit, currentPage * pagePostsLimit)
                    .map((post) => {
                        return (
                            <>
                                {/* <div key={post.id}>{post.name}</div> */}

                                <div class="course_main_1 clearfix" >
                                    <div class="col-sm-4">
                                        <div class="course_main_left card-image">
                                            <a href="login.html">
                                                {JSON.parse(post.profile_img)[0] ?
                                                    <img src={JSON.parse(post.profile_img)[0]} width="100%" style={{ maxHeight: "225px", display: "block", marginLeft: "auto", marginRight: "auto", width: "auto" }} />
                                                    :
                                                    <img src={avatarimg} width="100%" style={{ maxHeight: "225px", display: "block", marginLeft: "auto", marginRight: "auto", width: "auto" }} />
                                                }
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-sm-8">
                                        <div class="clearfix slider_inner course_main_right">
                                            <h5><a href="login.html">{post.name}</a> </h5>
                                            <p>Education :{post.education}
                                                Age :{post.Birth_year ? getCurrentYear - post.Birth_year : ''}
                                                Cast :{post.cost}
                                            </p>
                                            <div class="slider_inner_1 clearfix">
                                                <div class="col-sm-6 space_left">
                                                    <div class="slider_inner_1_left">
                                                        {/* <h4><a style={{ cursor: "pointer" }} onClick={() => { request(post.user_id); }}>Send Request</a></h4> */}
                                                        {/* <button onClick={()=>onClick1(post.name) } 
                                style={{backgroundColor:req.isActive && req.req_name == post.name?"red":"blue"}}
                                id={post.name}>Request</button> */}
                                                        <h4><a style={{ cursor: "pointer" }} onClick={() => { request(post.user_id); }}>{ids.includes(post.user_id) ? 'Sent' : 'Send Request'}</a></h4>
                                                        {/* working send request <button onClick={() => onClick1(post.name)}
                                  style={{ backgroundColor: req.some(vendor => vendor['req_name'] === post.name) ? "red" : "blue" }}
                                  id={post.name}>Send Request</button> */}

                                                    </div>
                                                </div>
                                                <div class="col-sm-6 space_left">

                                                    <div class="slider_inner_1_right text-right">
                                                        {/* <h4><a href="login.html">See Details</a></h4> */}
                                                        {/* <span><NavLink to={`/lookprofile?${encodeURIComponent("getdata=post.user_i")}`}>{post.user_id}jj See Details</NavLink></span> */}
                                                        <span>
                                                            <NavLink to={`/lookprofile?${encodeURIComponent("getdata=" + post.user_id)}`} target="_BLANK">See Details</NavLink></span>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </>
                        )
                    }) : "no data  "}
                <Pagination
                    currentPage={currentPage}
                    itemsPerPage={pagePostsLimit}
                    onPageChange={(pageNumber) => setCurrentPage(pageNumber)}
                    totalItems={users.usersData.length}
                    pageNeighbours={2}
                />

                {/* </div> */}



                {/* </div> */}


            </div>

        </>
    )
}
