import React from 'react'
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage'
export const Data = async() => {
    await axios.get('https://perfectjodidar.com/shaadi_api/get.php').then(response => {
    
      return response.data.rel;
    });
}
export const LoadData = () => {
  return axios.get('https://perfectjodidar.com/shaadi_api/get.php')
    .then(response => response.data);
}
export const GetUserById = () => {

  return axios.get('https://perfectjodidar.com/shaadi_api/getUserDetails.php', { params: { userId: secureLocalStorage.getItem("cust_id") } })
  .then(response => 
    response.data);
  
}
