import React, { useEffect, useState } from 'react'
import { LoadData, GetUserById } from '../functions/CommonData'
import axios from 'axios';
import { useForm } from 'react-hook-form'
import { toast, ToastContainer } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';

export const Living_country_details = () => {
    const [loadData, setLoadData] = React.useState([]);
    const [state, setState] = useState();
    const [city, setCity] = useState();
    const [details, setDetails] = useState();
    const [rdetails, setRdetails] = useState();

    const { register, handleSubmit, defaultValues } = useForm();

    useEffect(() => {
        LoadData().then(setLoadData);
        GetUserById().then(setRdetails);
    }, [])

    const onSubmit = async (data) => {
        // setDisable("disabled")
        // console.log(data);
        // console.log(details)
        // console.log(details.country)
        // console.log(details.state)

        let fd = new FormData();
        fd.append("country", details.country);
        fd.append("state", details.state);
        fd.append("city", data.city);
        fd.append("userid", secureLocalStorage.getItem("cust_id"));

        await axios.post(`https://perfectjodidar.com/shaadi_api/update_country.php`,
            fd
        ).then(response => {
            //console.log(response);
            if (response.data.success) {
                toast.success('Updated Successfully!', { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });

            } else {
                toast.error(response.data.msg, { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });
            }
        }
        ).catch((error) => {
            toast.error(error.msg, { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });

        });
    }
    // console.log(rdetails)
    const ChangeteState = (e) => {


        setDetails({ "country": e.target.value })
        axios.get('https://perfectjodidar.com/shaadi_api/getState.php?id=' + e.target.value).then(response => {

            setState(response.data.fetchstate)

        });
    }

    const ChangeCity = (e) => {

        axios.get('https://perfectjodidar.com/shaadi_api/getCity.php?id=' + e.target.value).then(response => {
            setDetails({ ...details, "state": e.target.value })
            setCity(response.data.fetchcity)
            // var f = response.data.fetchcity[0];
            // console.log(f.id);
            // console.log("inside State city", (response.data.fetchcity[0]).id);

        });
    }
    return (
        <>
            <div className="panel panel-default">

                <div className="panel-heading">Living  Details</div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="panel-body">

                        <div className="form-group">
                            <label >Your country details:</label>
                            
&nbsp;                                   {rdetails ? rdetails.coutry_name : ''}-{rdetails ? rdetails.state_name : ''}-{rdetails ? rdetails.city_name : ''}
                            
                        </div>
                        <div className="form-group">
                            <label >Country:</label>
                            <select className="form-control" name="country" onChange={(e) => ChangeteState(e)} >
                                <option>Select Country</option>

                                {
                                    // country.countryData.map((e, key) => {
                                    //     return <option key={key} value={e.id}>{e.name}</option>;
                                    // })selected={userData ? e.id == userData.religion ? 'selected' : '' : ''}
                                    loadData.fetchcountry ? loadData.fetchcountry.map((e, key) => {
                                        return <option key={key} value={e.id} >{e.name}</option>
                                    }) : ""
                                }
                            </select>
                        </div>
                        <div className="form-group">
                            <label >State:</label>
                            <select className="form-control slct" name="state" /*value={country.stateId} */ onChange={(e) => ChangeCity(e)} >

                                {state ? state.map((e, key) => {
                                    return <option key={key} value={e.id}>{e.name}</option>;
                                }) : ''}
                            </select>
                        </div>
                        <div className="form-group">
                            <label >City:</label>
                            <select className="form-control slct" name="city" /*defaultValue={country.cityData} */  {...register("city")}>

                                {city ? city.map((e, key) => {
                                    return <option key={key} value={e.id}>{e.name}</option>;
                                }) : ''}
                            </select>
                        </div>
                        <div className="col-md-6 col-md-offset-3" style={{ padding: "20px" }}>
                            {/* <button type="submit" class="btn btn-default btn-lg">Submit</button> */}
                            <input type="submit" name="login-submit" id="login-submit" className="form-control btn btn-reg" value="Update" ></input>
                        </div>

                    </div>
                </form>
            </div>

        </>
    )
}
