import React from 'react'
import { NavLink } from 'react-router-dom';
export const PageHeader = () => {
 
   return (
        <>
            <div id="header">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <nav className={`navbar navbar-default navbar-fixed-top navbar-shrink`} role="navigation">
                                <div className="navbar-header">
                                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
                                        <span className="sr-only">Toggle navigation</span>
                                        <span className="icon-bar"></span>
                                        <span className="icon-bar"></span>
                                        <span className="icon-bar"></span>
                                    </button>
                                    <a className="navbar-brand" href="index.html"><span style={{ background: "rgba(27, 180, 185, 0.90)", padding: "10px" }}>Perfect</span><span style={{ background: "#ea9215", padding: "10px" }}> Jodidar</span> </a>
                                </div>
                                <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                                    <ul className="nav navbar-nav navbar-right">
                                        <li >
                                            {/* <a href="index.html"><i class="fa fa-fw fa-home"></i> Home</a> */}
                                            <NavLink to="/"><i className="fa fa-fw fa-home"></i> Home</NavLink>
                                        </li>
                                        <li>
                                            {/* <a href="blog.html"> Register Free</a> */}
                                            <NavLink style={({ isActive }) => (
                                                isActive ? { backgroundColor: '#fec503', borderRadius: '3px', color: '#000' } : {})} to="/register">Register Free</NavLink>
                                        </li>
                                        <li>
                                            {/* console.log("sssss",navData); */}
                                            {/* {{{navData.isActive}}} */}
                                            {/* <a href="detail.html"> Login</a> */}

                                            <NavLink style={({ isActive }) => (
                                                isActive ? { backgroundColor: '#fec503', borderRadius: '3px', color: '#000' } : {})}
                                                to="/login"><i className="fa fa-sign-in" aria-hidden="true"></i> Login</NavLink>
                                        </li>

                                        {/* 
                                        <NavLink
                                            to="/login"
                                            style={({ isActive }) =>
                                                (isActive ? { color: 'red' } : { color: 'blue' })}
                                        >
                                            Some Page
                                        </NavLink> */}


                                        {/* <i class="fa fa-registered" aria-hidden="true"></i><li className="">Home</li>
                                        <li>Register Free</li>
                                        <li>Login</li> */}
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
