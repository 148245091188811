import React, { useEffect, useState } from 'react'
import FrontHeader from './FrontHeader'
import { useParams, useLocation } from 'react-router-dom'
import axios from 'axios';
import secureLocalStorage from "react-secure-storage";
import { NavLink, useNavigate } from 'react-router-dom'
import { toast, ToastContainer } from 'react-toastify';
import avatarimg from "../img/avatarimg.png";

export const Look_profile = () => {
    let { id } = useParams();
    const nav = useNavigate();
    const [lookUpUser, setLookUpUser] = useState([]);

    const [imageUser, setImageUser] = useState();
    async function request(user_id) {
//        console.log("ffff", user_id);
        let fd = new FormData();
        fd.append("user_id", secureLocalStorage.getItem("cust_id"));
        fd.append("req_user_id", user_id);


        await axios.post(`https://perfectjodidar.com/shaadi_api/insert_request.php`,
            fd
        ).then(response => {
            //console.log(response);
            if (response.data.success) {
                toast.success('Request Send Successfully!', { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });

                setTimeout(() => {
                    nav('/front');
                }, 2000);
            } else {
                //setGloabalError(response.data.msg);
                toast.error("There is some technical problem", { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });
            }
        }
        ).catch((error) => {
            toast.error(error, { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });

        });
    }

    function useQuery() {
        return new URLSearchParams(decodeURIComponent(useLocation().search));
    }
    let query = useQuery();
    //console.log("ll dddd", query.get("getdata"));
    const [index, setIndex] = useState(0)

    const next = () => {
      //  console.log("checking data", imageUser.length);
        if (index === imageUser.length - 1) {
            setIndex(0)
        } else {
        //    console.log("udbex", index);
            setIndex(index + 1)
        }
    }
    const prev = () => {
        if (index === 0) {
            setIndex(imageUser.length - 1)
        } else {
            setIndex(index - 1)
        }
    }


    async function fetchData() {

        await axios.get('https://perfectjodidar.com/shaadi_api/lookUpDetails.php', { params: { userId: query.get("getdata"), loginId: secureLocalStorage.getItem("cust_id") } }).then(response => {
            setLookUpUser(response.data);
            setImageUser(response.data.profile_img ? JSON.parse(response.data.profile_img) : '');
        }).catch((error) => {
            console.log(error.message);
            //setGloabalError(error.message);
        })

    }
    useEffect(() => {
        fetchData();
    }, []);
    const box = {
        background: "#fff",
        padding: "30px 20px 30px 20px",
        boxShadow: "0px 2px 2px 2px #d6d6d6",
        marginTop: "20px"
    }
    const Thumbnail = ({ arr, image, index }) => {
        return (<div className="tumbnail">
            {
                arr ? arr.map((imgsrc, i) => (

                    <img
                        key={i}
                        height="50"
                        src={imgsrc}
                        onClick={() => image(i)}
                        className={index === i ? 'active' : ''}
                    />
                )) : ''
            }
        </div>)
    }

    return (
        <>
            <FrontHeader />
            <ToastContainer />

            {lookUpUser ? (

                <div className="container" style={{ marginTop: "100px" }}>
                    <div className="row" style={box}>
                        {/* {!lookUpUser? ( */}
                        <div className="col-sm-4 " >
                            <div className="d-flex flex-column align-items-center text-center">
                                {/* <img className="rounded-circle" width="150px" src="https://st3.depositphotos.com/15648834/17930/v/600/depositphotos_179308454-stock-illustration-unknown-person-silhouette-glasses-profile.jpg" /> */}
                                {/* <img className="rounded-circle" width="150px" src={imageUser ? imageUser[0] : ''}/> */}
                                <img className="rounded-circle" width="150px" src={imageUser?imageUser[0]?imageUser[0]:avatarimg:''}/>
                                
                            {/* //     imageUser == '' ?  />
                            //    :<img className="rounded-circle" width="150px" src={avatarimg} />
             */}
                               
                                <br />
                                {/* ccc{query.get("name")} */}

                                <span className="font-weight-bold">Email Address: </span>
                                <span className="text-black-50">
                                    {lookUpUser.email_address ? lookUpUser.email_address : <NavLink to="/profile/premember" style={{ cursor: "pointer", color: "black" }}><i class="fa fa-lock"></i></NavLink>}
                                </span>
                                <span>

                                </span>
                            </div>
                        </div>
                        
                        {/* ):''} */}
                        <div className="col-sm-8">

                            {/* <div className="d-flex justify-content-between align-items-center mb-8">
                                    <h4 className="text-center">Profile </h4>
                                </div> */}
                            <div className="details">
                                {/* <p>Age:  32 Yrs, 5 Ft / 152 Cms  {lookUpUser.email_address}</p> */}
                                <p>Birth Date:  {lookUpUser.birth_date}/{lookUpUser.birth_month}/{lookUpUser.birth_year}</p>

                                <p>Religion:  {lookUpUser.reli}  </p>
                                <p>Caste:  {lookUpUser.cast ? lookUpUser.cast : " Not specified"}</p>
                                <p>Location: {lookUpUser.city_name ? lookUpUser.city_name : 'Not specified'}-{lookUpUser.state_name},{lookUpUser.country_name}  </p>
                                <p>Education:   {lookUpUser.degree_name ? lookUpUser.degree_name : "Not specified"}  </p>
                                <p>Profession: {lookUpUser.profession ? lookUpUser.profession : 'Not specified'}  </p>

                                <i class="fa fa-mobile"> &nbsp;{lookUpUser.contact_no ? lookUpUser.contact_no : <NavLink to="/profile/premember" style={{ cursor: "pointer", color: "black" }}><i class="fa fa-lock"></i></NavLink>}</i>
                                &nbsp; <i class="fa fa-user"> &nbsp; {lookUpUser.email_address ? lookUpUser.email_address : <NavLink to="/profile/premember" style={{ cursor: "pointer", color: "black" }}><i class="fa fa-lock"></i></NavLink>}</i>
                                {/* <div className="col-md-6"><label className="labels">hetali Topiwala</label></div> age */}
                                {/* <div className="col-md-6"><label className="labels">topiwala</label></div>query.get("getdata") */}
                                <div class="slider_inner_1_left">
                                     {/* <h4><a style={{ cursor: "pointer" }} onClick={() => { request(query.get("getdata")); }}>Send Request</a></h4> */}
                                     </div>
                            </div>
                        
                        </div>
                        &nbsp;&nbsp;<i class="fa fa-lock"></i> = For Accepted user request AND Premium Members
                    </div>
                </div>
            ) : (
                <div className="container" style={{ marginTop: "100px" }}>
                    No Data Found
                </div>
            )}
            {lookUpUser ? (

                <div className='container'>
                    <div className="row" style={box}>

                        <div class="col-sm-12 space_all">
                            <div class="details_13 clearfix">
                                <div class="details_12">
                                    <h2>Personal Information</h2>
                                </div>
                                <div class="details_14">
                                    <h4><i class="fa fa-pinterest"></i>About My Self</h4>

                                    {/* {
    //working code 
                                        imageUser ? imageUser.map(img => (
                                            <b>{img} <br /></b>
                                        )) : ''
                                    } */}

                                    {/* {lookUpUser.profile_img?Array.from(lookUpUser.profile_img).map((ki,i)=> <div>fff</div>):''} */}
                                    <p>{lookUpUser.description ? lookUpUser.description : ''}</p>
                                    {/* <p>My daughter is a Manager with a Master's degree currently working in Private sector in Gurgaon.<br />
                                        We come from a Middle class, Nuclear family background with Traditional values.</p> */}
                                </div>

                                <div class="details_15">
                                    <h4><i class="fa fa-user"></i>Basic Details</h4>
                                </div>
                                <div class="col-sm-12">
                                    <div style={{ display: "flex" }}>
                                        <div class="col-sm-3">
                                            <div class="details_16">
                                                <ul>
                                                    <li>Name</li>
                                                    <li>Age</li>
                                                    {/*<li>Height</li>
                                                    <li>Weight</li> */}
                                                    <li>Mother Tongue</li>
                                                    <li>Marital Status</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="details_16">
                                                <ul>
                                                    <li>: {lookUpUser.first_name}</li>
                                                    <li>: {(new Date().getFullYear()) - lookUpUser.birth_year} years</li>
                                                    {/* <li>: 5 Ft 3 In / 160 Cms baki</li>
                                                    <li>: 45 Kgs / 99 lbsbaki</li> */}
                                                    <li>: {lookUpUser.mother_tonque}</li>
                                                    <li>: {lookUpUser.m_status}</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="details_16">
                                                <ul>
                                                    {/* <li>Body Type</li>
                                                    <li>Complexion</li> */}
                                                    <li>Birth Time</li>
                                                    {/* <li>Physical Status</li> */}
                                                    <li>Eating Habits</li>
                                                    <li>Drinking Habits</li>
                                                    <li>Smoking Habits</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="details_16">
                                                <ul>
                                                    {/* <li>: Slimbaki</li>
                                                    <li>: Fairbaki</li> */}
                                                    <li>: {lookUpUser.birth_hr}hr {lookUpUser.birth_min}min {lookUpUser.birth_sec}sec</li>
                                                    {/* <li>: Normalbaki</li> */}
                                                    <li>: {lookUpUser.eating_habits}</li>
                                                    <li>: {lookUpUser.drink ? 'yes' : 'Never drinks'}</li>
                                                    <li>: {lookUpUser.smoke ? 'yes' : 'Never smokes'}</li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-sm-12 space_all">
                                    <div class="details_15">
                                        <h4><i class="fa fa-mobile"></i>Contact Details</h4>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div class="col-sm-3">
                                            <div class="details_17">
                                                <ul>
                                                    <li>Contact Number</li>
                                                    {/* <li>Parent Contact</li>
                                                    <li>Chat Status</li>
                                                    <li>Send Mail</li> */}
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="details_16">
                                                <ul>
                                                    <li>: {lookUpUser.contact_no ? lookUpUser.contact_no : <NavLink to="/profile/premember" style={{ cursor: "pointer", color: "black" }}><i class="fa fa-lock"></i></NavLink>}</li>
                                                    {/* <li>: Available</li>
                                                    <li>: Online</li>
                                                    <li>: Online</li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-3"></div>
                                    <div class="col-sm-3"></div>
                                </div>
                                <div class="col-sm-12 space_all">
                                    <div class="details_15">
                                        <h4><i class="fa fa-book"></i>Religion Information</h4>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div class="col-sm-3">
                                            <div class="details_17">
                                                <ul>
                                                    <li>Religion</li>
                                                    <li>Caste / Sub Caste</li>
                                                    {/* <li>Gothram</li> */}
                                                    {/* <li>Star / Raasi</li> */}
                                                    <li>Dosh</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-sm-5">
                                            <div class="details_16">
                                                <ul>
                                                    <li>: {lookUpUser.reli ? lookUpUser.reli : 'Not Specified'} </li>
                                                    <li>: {lookUpUser.cast ? lookUpUser.cast : 'Not Specified'}</li>
                                                    {/* <li>: Hindu</li>
                                                    <li>: Brahmin Pandit (Caste No Bar) / Brahmin</li>
                                                    <li>: Garga</li> */}
                                                    {/* <li>: Hastha / Kanya (Virgo)</li> */}
                                                    <li>: {lookUpUser.manglik ? 'Manglik' : 'Not Specified'} </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-1"></div>
                                    <div class="col-sm-3"></div>
                                </div>
                                <div class="col-sm-12 space_all">
                                    <div class="details_15">
                                        <h4><i class="fa fa-mobile"></i>Photos</h4>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div class="col-sm-3">
                                        </div>
                                        <div class="col-sm-6">

                                            <div>
                                                <div className="slideshow">
                                                    <img className="mainImg" src={imageUser ? imageUser[index] : ''} style={{ maxWidth: "100%", height: "auto" }} />
                                                    <div className="actions" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        {/* <button onClick={prev}>👈</button>
                                            <button onClick={next}>👉</button> */}
                                                        <button onClick={prev}>&lt;&lt;Prev</button>
                                                        <button onClick={next}>Next&gt;&gt;</button>
                                                    </div>
                                                    <Thumbnail arr={imageUser} image={setIndex} index={index} />
                                                </div>
                                            </div>

                                        </div>

                                        <div class="col-sm-3">
                                        </div>
                                    </div>
                                </div>





                                <div class="col-sm-12 space_all">
                                    <div class="details_15">
                                        <h4><i class="fa fa-map"></i>Location</h4>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div class="col-sm-3">
                                            <div class="details_17">
                                                <ul>
                                                    <li>Country</li>
                                                    <li>State</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="details_16">
                                                <ul>
                                                    <li>: {lookUpUser.country_name ? lookUpUser.country_name : 'Not Specified'}</li>
                                                    <li>: {lookUpUser.state_name ? lookUpUser.state_name : 'Not Specified'}</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="details_16">
                                                <ul>
                                                    {/* <li>Citizenship</li> */}
                                                    <li>City</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="details_16">
                                                <ul>
                                                    {/* <li>: Augue</li> */}
                                                    <li>: {lookUpUser.city_name ? lookUpUser.city_name : 'Not Specified'}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 space_all" >
                                    <div class="details_15">
                                        <h4><i class="fa fa-user"></i>Professional Information</h4>
                                    </div>
                                    <div style={{ display: "flex" }}>
                                        <div class="col-sm-3">
                                            <div class="details_17">
                                                <ul>
                                                    <li>Education</li>
                                                    {/* <li>Education in Detail</li> */}
                                                    <li>Employed in</li>
                                                    <li>Occupation</li>
                                                    {/* <li>Occupation in Detail</li> */}
                                                    <li>Annual Income</li>
                                                </ul>
                                            </div>
                                        </div>
                                        <div class="col-sm-3">
                                            <div class="details_16">
                                                <ul>
                                                    <li>:  {lookUpUser.degree_name ? lookUpUser.degree_name : 'Not Specified'}</li>
                                                    {/* <li>: MBA (HR)</li> */}
                                                    <li>: {lookUpUser.employee_in ? lookUpUser.employee_in : 'Not Specified'}</li>
                                                    <li>: {lookUpUser.profession ? lookUpUser.profession : 'Not Specified'}</li>
                                                    {/* <li>: Astt.Manger (HR)</li> */}
                                                    <li>:  {lookUpUser.package ? 'RS.' + lookUpUser.package + ` PA` : 'Not Specified'} </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-sm-3"></div>
                                    <div class="col-sm-3"></div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            ) : ''}
            {/* ):''} */}
        </>

    )
}


