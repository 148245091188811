import React from 'react'
import { PageHeader } from './PageHeader'
export const Terms_and_condition = () => {

    return (
        <>
            <PageHeader />

            <div className="container" >
                <div className="row">

                    <div className="col-md-12" style={{ marginTop: "100px" }}>
                        {/* <h1>Login</h1>
                        <hr className="star-primary" /> */}
                        <h1>Terms and Condition</h1>

                        <br />
                        {/* <h2>About Perfectjodidar.com </h2><br /> */}


                        <h3>Eligibility</h3><br />

                        <p>
                            Perfectjodidar matrimony shall act on the basis of the information that may be submitted by you, believing the same to be true and accurate even if the information is provided during the registration by your family, friends, relatives on your behalf under your express consent . perfectjodidar matrimony is under no obligation to verify the accuracy or genuineness of the information submitted by you.
                        </p><br/>
                        <p>1. The minimum age for registering in perfectjodidar matrimony is 18 years for women and 21 years for men.</p>
                        <p>2. Users must provide accurate and up-to-date information during the registration process.</p>
                        <p>3. The Provider reserves the right to refuse service to any User without providing any reason.</p>
                        <p>4. You have gone through the Terms and Conditions and agree to be bound by them.</p><br />
                        

                        {/* <h3>Communication</h3><br />
                        <p>
                            1. You hereby expressly solicit and invite perfectjodidar matrimony and/or its authorized personnel to communicate to you through any telecom resources in the registered number provided by you to explain, explicate and clarify the various services provided by perfectjodidar matrimony and to assist, aid or support you in availing the said services of perfectjodidar matrimony. <br /><br/>
                            2. By choosing to continue with registration on perfectjodidar, you agree to receive transactional SMS and promotional SMS from perfectjodidar. If at any time, you wish to discontinue receiving the communications (including, but not limited to emails, sms and phone calls) from perfectjodidar matrimony, you may by email to info@perfectjodidar.com to indicate the same to perfectjodidar matrimony and/or its authorized personnel regarding such discontinuance and You hereby agree that, unless expressly communicated to You about discontinuing communications from perfectjodidar matrimony to perfectjodidar matrimony and/or its authorized personnel, it will be deemed to be that you want to continue and solicit and invite all such or other communications from perfectjodidar matrimony. <br /><br/>
                            3. You are representing that you or the mobile number submitted by you is not registered with the Do Not Disturb / National Customer Preference Register and/or you have already changed your registration suitably. <br /><br/>
                            4. Further and in any event, you do hereby also unconditionally agree and undertake that this invitation and solicitation shall supersede any preferences set by you with or registration done with the Do Not Disturb ("DND Register")/ National Customer Preference Register ("NCPR"). Without prejudice to the aforesaid and in any event, by expressly inviting and soliciting the services from perfectjodidar matrimony, you also unconditionally agree that your rights under the Telecom Commercial Communications Customer Preference Regulations, 2010 or any subsequent amendments thereto or under NCPR, are kept in abeyance or remain extinguished till you expressly communicate for discontinuation of relationship. <br /><br/>
                            5. You also unconditionally agree to indemnify perfectjodidar matrimony against all losses, damages, penalties, costs or consequences whether direct or indirect, that may arise out of any breach or violation of the aforesaid representation, commitment and undertaking.<br /><br/>
                        </p> 
                        <br />*/}

                        <h3>Privacy and Data Security</h3><br />

                        <p>We are committed to protecting your privacy and personal information. By using our matrimonial services, you agree to our Privacy Policy, which outlines the collection, use, and disclosure of your personal data. We employ reasonable security measures, but we cannot guarantee absolute security of your data.</p>
                        <br />

                        <p>The site collects all the data of user which fill up in form, for getting a perfect match for u. The site never collects user's financial details like credit card/ debit card numbers, bank account details etc. 
</p>
                        <br />
                        <p>By logging in the site or application user is being agreed for showing his or her data to another user.</p>
                        <br />
                        <p>Users ( he or she) undertake to use the Platform for their personal purposes. Using content from the Platform for creating derivative works with a commercial motive without prior written consent from the site or application is strictly prohibited for every one.
</p>
                        <br />
                        <p>Any information filled or given by user to this platform may not be true, correct or reliable. The site or application is completely not responsible for any user's misinformation. User have to confirm the details their self provided here.
</p>
                        <br />
                        <p>The site and application never advice any user to contact another user, user can not make responsible site or application or owner for misinformation given by another user.
</p>
                        <br />
                        <p>The site and application provides facility to make profile for users which will be displayed on the site and application. The site and application Never shares users data with anyone. The site and application will take possible cares to make data safe. Still if it will get misused by another in that case the site, application or user is not responsible or user can not make responsible site , application or owner.
</p>
                        <br />
                        <p>When user makes true profile on this platform at that time he takes responsibility to make only true profile. The site and application never supports or take responsibility or undertake that user have filled each information truly. User have to confirm the information their self before doing any kind of action.
                        </p><br/>
                        <p>If the site and application found that user have added some misinformation or vulgar literature, or user harressing some one or user not being agreed to remove the contents, then application and site will remove or delete profile.
                        </p><br/>
                        <p>User is fully responsible for his or her privacy, password or activity , credit card, debit card, bank account details, documents he or she uploads , online offline transaction(s) or any action he or she takes.The site and application does not responsible for this. The site and application does not store any financial details like bank account, credit ,debit card details the payments are being done on payment gateway sites, not through this application or site.
                        </p><br/>
                        <p>The site and application is not responsible for interaction between users through email chat or any medium. The site and application doesn't monitor such chats, even not responsible for any litigation or case.
                        </p><br/>
                        <p>We employ reasonable security measures, but we cannot guarantee absolute security of your data.
</p><br/>                        

                        <h3>                    Trademarks and Site Content</h3><br />

                        <p>                        Trademarks indicated on our site is the property of perfectjodidar matrimony, or its subsidiaries, in India and other countries. Other perfectjodidar matrimony graphics, logos, page headers, button icons, scripts, and service names are trademarks or trade address of perfectjodidar matrimony or its subsidiaries. perfectjodidar matrimony's trademarks and trade address may not be used in connection with any product or service that is not perfectjodidar matrimony, in any manner that is likely to cause confusion among customers or in any manner that disparages or discredits perfectjodidar matrimony. All other trademarks not owned by perfectjodidar matrimony or its subsidiaries that appear on this site are the property of their respective owners, who may or may not be affiliated with, connected to, or sponsored by perfectjodidar matrimony or its subsidiaries. You shall not use/copy/modify and reuse any CSS,site design,images in any format without the prior permission from perfectjodidar matrimony.
                        </p>
                        <br />

                        <h3>No Guarantee Matchmaking</h3><br />

                        <p>perfectjodidar matrimony doesn't assure that the registered users whether free/paid, will be able to find their match of interest. perfectjodidar is only a online platform to help you in your partner search. We do not offer guarantee matchmaking services and the availability of matching profiles within a community.
                        </p>

                        <br /><h3>                        Authenticity of Profiles</h3><br />

                        <p>perfectjodidar matrimony does not authenticate, vet, screen, endorse or investigate any information or assertion comprised in the matrimonial listings, or any other content on the website, nor does it in any manner whatsoever certify or attest the same to be correct or true.Perfectjodidar matrimony advices its registered users to verify the authenticity of all the profile details provided in perfectjodidar.com before entering into marriage alliance.
                        </p><br />

                                                
                        <p>The site and application have all rights to delete or remove message, profile, profiles, photo or any information which found wrong or vulgar. If the. Site and application get complaint or reported by any user, the site will do prilimanry investigation and the. Site have all rights to remove or delete whole profile and membership and stop to give service to reported user.the site and application believes that user who is creating profile here his or her intense to get married, if the site and application found that user Don't want to do the same or his intention have been changed at that time the site and application will remove or delete that user.
</p><br/>
<p>The site and application have full right To delete or remove duplicate or fake profile or having a vulgur details or reported by someone.</p><br/>
                        <h3> Online Conduct / Rules of Conduct</h3><br />

                        <p>You are responsible for the content and information [including profile and photograph] you post or transmit through perfectjodidar matrimony's services. You will not post or transmit any content or information [in whatever form they may be contained] that is defamatory, blasphemous, abusive, obscene, sexually oriented, profane, intimidating, illegal or in violation of the rights of any person, including intellectual property rights. No content or information shall contain details or particulars of any of perfectjodidar matrimony's competitors, including their contact details. perfectjodidar matrimony has the right to suspend your profile without any prior notice. perfectjodidar matrimony reserves the right to edit/delete your photo/horoscope/profile details if it's not a valid/clear/related one. perfectjodidar matrimony reserves the right to modify your profile if any prohibitive or objectionable content is found, or in the case of your contact details being entered in irrelevant fields. perfectjodidar matrimony may also modify the profile for other reasons not mentioned herewith.
                        </p>

                        <br /><h3>Information loss or misuse</h3><br />

                        <p>
Incase of any information/data loss related to registered Users in perfectjodidar.com due to any reason, perfectjodidar matrimony will not be responsible for data/information recovery. All the information provided by Profiles including documents will be viewed/screened/modified(if required) by perfectjodidar matrimony employees. Perfectjodidar matrimony is not responsible for any mis-use of your data or your documents by other registered Profiles.
                        </p>

                        <br />
                        <p>Websites to which links are given on the site and application the contents thereof are the sole responsibility of the promoters of such websites and the site and application does not, in any manner whatsoever, recommend, authenticate, endorse, verify or certify these websites or any contents or links there. The site and application does not take any responsibility for the privacy practice adopted by such other websites and therefore the site and application recommends Users to read the privacy statement of each and every website that they visits. The site And application does not take any responsibility or endorse the authenticity of the other online services displayed on the Platform which may offer various services like a lucky draw, win a free trip, win free tickets, astrology, palmistry etc. User of the Platform shall at its sole risk and responsibility click and surf on to such other website(s) / links which is/are being displayed on the Platform. Users shall always bear in mind that at a single stroke/click of the mouse on such links/website, it gets connected through the Transmission Control Protocol (TCP) / Internet Protocol (IP) network of the said Users and not through the IP of the Platform. Company also does not suggest/encourage Users to furnish personal information and specially the Profile ID / User Name of the User on the Platform to such or any other website(s).be aware before clicking on advertisement pages.</p><br/>
                        <h3>Memberships</h3><br />

                        <p>Registration on perfectjodidar is free.For every bride or groom, registration is free.Pay a small fee to communicate with one another.and access or are able to see one other's contact information and make contact.
                        </p>
                        <br /><h3>Payments & Purpose of Payment Gateway</h3><br />

                        <p>We accept your payments through secured and trusted Payment Gateways. We don't support any other Payment mode currently. Payment gateways protect your Payment Card details or Net Banking details by encrypting sensitive information, such as credit card numbers, to ensure that information is passed securely between the customer and the merchant and also between merchant and the payment processor.
                        </p>
                        <br />
                        <p>The site and application undertakes no liability for free services. Company reserves its right to amend / alter or change all or any disclaimers or these Terms at any time without any prior notice. All terms / disclaimers whether specifically mentioned or not shall be deemed to be included if any reference is made to them. The continued usage of the Platform by a User shall constitute deemed acceptance by the User of the amended Terms.
</p><br/>
                        <h3>Refund & Cancellation Policy</h3><br />

                        <p>If you choose to terminate your Paid membership or request for refund of any Add-on that has been bought in perfectjodidar.com, the MEMBERSHIP/ADD-ON FEES ARE NOT REFUNDABLE under any circumstances. Your membership in the perfectjodidar service is for your sole, personal use. You may not authorize others to use your membership and you may not assign or transfer your account to any other person or entity.
                        </p>
                        <br /><h3>Effective Date</h3><br />

                        <p>The above policy is effective since 1 JUNE 2023.The site and application have all rights reserved. For any queries, please write to perfectjodidar.info@gmail.com or call us on +91 9104596713.
                        </p><br/><br/>





                    </div>


                </div>
            </div>
        </>
    )
}
