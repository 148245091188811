import React, { useEffect, useState ,useContext} from 'react'
import secureLocalStorage from 'react-secure-storage';
import axios from 'axios';
import { useForm } from 'react-hook-form'
import { toast, ToastContainer } from 'react-toastify';

export const UserLogin = () => {

    const [details, setDetails] = useState();
    const [disable, setDisable] = useState('');
    const { register, handleSubmit, defaultValues } = useForm();
    
    const onSubmit = async (data) => {        
        setDisable("disabled")
        let fd = new FormData();        
        fd.append("username", data.username);
        fd.append("password", data.password);
        fd.append("userid", secureLocalStorage.getItem("cust_id"));

        await axios.post(`https://perfectjodidar.com/shaadi_api/update_login.php`,
            fd
        ).then(response => {
        //    console.log(response);
            if (response.data.success) {
                toast.success('Updated Successfully!', { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });

            } else {
                toast.error(response.data.msg, { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });
            }
        }
        ).catch((error) => {
            toast.error(error.msg, { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });

        });
    }
    useEffect(() => {
        fetchData();
    }, []);
    async function fetchData() {
        await axios.get('https://perfectjodidar.com/shaadi_api/getUserDetails.php', { params: { userId: secureLocalStorage.getItem("cust_id") } }).then(response => {
            setDetails(response.data);
        }).catch((error) => {
            toast.error(error.msg, { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });
        })

    }
    return (
        <>
        <ToastContainer />
            <div className="panel panel-default">

                <div className="panel-heading">Login Details</div>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="panel-body">

                        <div className="form-group">
                            <label >User Name: *</label>
                            <input type="text" {...register("username")} className="form-control" id="username" placeholder="Enter username" name="username" defaultValue={details ? details.user_name : ''} />

                        </div>

                        <div className="form-group">
                            <label >Password: *</label>
                            <input type="password" {...register("password")} className="form-control" defaultValue={details ? details.password : ''} />
                        </div>

                        <div className="col-md-6 col-md-offset-3" style={{ padding: "20px" }}>
                            <input type="submit" name="login-submit" id="login-submit" className="form-control btn btn-reg"  disabled={disable} value="Update"></input>
                        </div>

                    </div>
                </form>
            </div>

        </>
    )
}
