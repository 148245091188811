import React, { useEffect, useState } from 'react'
import 'bootstrap-toggle/css/bootstrap-toggle.min.css';
import 'bootstrap-toggle/js/bootstrap-toggle.js';
import { LoadData, GetUserById } from '../functions/CommonData'
import { useForm } from 'react-hook-form'
import secureLocalStorage from 'react-secure-storage'
import { toast, ToastContainer } from 'react-toastify';
import axios from 'axios';


export const Basic_details = () => {
    const [loadData, setLoadData] = React.useState([]);
    const [userData, setUserData] = React.useState([]);
    const [habit, setHabit] = useState({ drink: '', smoke: '' });    
    const { register, handleSubmit, defaultValues } = useForm();
    
    useEffect(() => {
        LoadData().then(setLoadData);
        GetUserById().then(setUserData)

    }, [])
    const oncheck = (e) => {
       // console.log(e.target.checked);
        setHabit({
            ...habit,
            [e.target.name]: e.target.checked
        })
    
    }
  //  console.log("ssss", habit)
    const onSubmit = async (data) => {
        // setDisable("disabled")
        
        let fd = new FormData();
        fd.append("education", data.education == 0 ? '' : data.education);
        fd.append("jobprofile", data.jobprofile == 0 ?'':data.jobprofile);
        fd.append("pkg", data.pkg);
        fd.append("employeeid", data.employeeid == 0 ? '' : data.employeeid);
        fd.append("drink", habit.drink);
        fd.append("smoke", habit.smoke);      
        fd.append("manglik", habit.manglik);      
        fd.append("userid", secureLocalStorage.getItem("cust_id"));

        await axios.post(`https://perfectjodidar.com/shaadi_api/update_basics.php`,
            fd
        ).then(response => {
//            console.log(response);
            if (response.data.success) {
                toast.success('Updated Successfully!', { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });

            } else {
                toast.error(response.data.msg, { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });
            }
        }
        ).catch((error) => {
            toast.error(error.msg, { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });

        });
    }

    return (
        <>

            <div className="panel panel-default">

                <div className="panel-heading">Basic Details</div>
                <div className="panel-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label >Education:</label>
                            <select className="form-control" name="education" /*value={globalData.degree.id}*/{...register("education")}>
                                <option key="0" value="0">--Select Higest Degree--</option>

                                {/* {
                                globalData.degree.map((e, key) => {
                                    return <option key={key} value={e.id}>{e.name}</option>;
                                })
                            } selected={userData ? e.id == userData.religion ? 'selected' : '' : ''}*/}
                                {
                                    loadData.degree ? loadData.degree.map((e, key) => {
                                        return <option key={key} value={e.id} selected={userData ? e.id == userData.education ? 'selected' : '' : ''}>{e.name}</option>
                                    }) : ""
                                }
                            </select>
                        </div>
                        <div className="form-group">
                            <label >Job profile:</label>
                            <select className="form-control" name="jobprofile" {...register("jobprofile")}/*value={globalData.occupa.id}*/>
                                <option key="0" value="0">--Select Job Profile--</option>

                                {/* {
                                globalData.occupa ? globalData.occupa.map((e, key) => {
                                    return <option key={key} value={e.id}>{e.name}</option>;
                                }) : ""
                            } */}
                                {
                                    loadData.occu ? loadData.occu.map((e, key) => {
                                        return <option key={key} value={e.id} selected={userData ? e.id == userData.job_profile ? 'selected' : '' : ''}>{e.name} </option>
                                    }) : ""
                                }
                            </select>

                        </div>
                        <div className="form-group">
                            <label >Package:</label>
                            <input type="number" name="pkg" className="form-control" defaultValue={userData ? userData.package ? userData.package : '' : ''} {...register("pkg")} />
                        </div>
                        <div className="form-group">

                            {/* Mangalik: <input type="checkbox"  data-toggle="toggle" data-size="lg" /> */}
                            {/* <label>Smoke:</label> <input type="checkbox" data-toggle="toggle" data-size="lg" />
                        <label>Drink: </label><input type="checkbox" data-toggle="toggle" data-size="lg" /> */}
                            <label>Smoke:</label>
                            {userData ? userData.smoke != null && userData.smoke == 1?

                                <><input type="checkbox" name="smoke" class="lgcheckbox" defaultChecked="true" onClick={oncheck} /><br /></>
                                :
                                <><input type="checkbox" name="smoke" class="lgcheckbox" onClick={oncheck} /><br /></>
                                : ''}


                            <label>Drink: </label>

                            {userData ? userData.drink != null && userData.drink == 1?
                                <><input type="checkbox" name="drink" class="lgcheckbox" defaultChecked="true"  onClick={oncheck} /><br /></>
                                :
                                <><input type="checkbox" name="drink" class="lgcheckbox" onClick={oncheck} /><br /></>
                                : ''}



                        </div>
                        <div className="form-group">
                            <label>Mangalik: </label>
                            {userData ? userData.manglik != null && userData.manglik == 1?
                                <><input type="checkbox" name="manglik" class="lgcheckbox" defaultChecked="true"  onClick={oncheck} /><br /></>
                                :
                                <><input type="checkbox" name="manglik" class="lgcheckbox" onClick={oncheck} /><br /></>
                                : ''}
                            
                        </div>
                        <div className="form-group">
                            <label >Employee In:</label>
                            <select className="form-control" name="employee_id" id="employee_id" {...register("employeeid")}>
                                <option value="0" key="0">--Select Employee In--</option>

                                <option value="Government" selected={userData ? userData.employee_in == "Government" ? 'selected' : '' : ''}>Government</option>
                                <option value="Private" selected={userData ? userData.employee_in == "Private" ? 'selected' : '' : ''}>Private</option>
                                <option value="Business" selected={userData ? userData.employee_in == "Business" ? 'selected' : '' : ''}>Business</option>
                                <option value="Defence" selected={userData ? userData.employee_in == "Defence" ? 'selected' : '' : ''}>Defence</option>
                                <option value="Not Working" selected={userData ? userData.employee_in == "Not Working" ? 'selected' : '' : ''}>Not Working</option>
                            </select>
                        </div>
                        <div className="col-md-6 col-md-offset-3" style={{ padding: "20px" }}>
                            {/* <button type="submit" class="btn btn-default btn-lg">Submit</button> */}
                            <input type="submit" name="login-submit" id="login-submit" className="form-control btn btn-reg" value="Update" /*disabled={disable}*/></input>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
