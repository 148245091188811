import React from 'react'

export function Number(length) {
    const number = [];
    for (let i = 1; i <= length; i++) {
        number.push(i)
    }
    return number;
}
export function searchNumber(length) {
    const number = [];
    for (let i = 21; i <= length; i++) {
        number.push(i)
    }
    return number;
}
export function randomStrNum(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
}


