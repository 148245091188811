import React,{useState,useEffect} from 'react'
import {Request_list} from './Request_list'
import axios from 'axios';
import secureLocalStorage from 'react-secure-storage';
export const Request_accept = () => {
    const [users, setUsers] = useState({ usersData: '', size: 0 });
   const g = "ddd";
  
    async function fetchData() {

        await axios.get('https://perfectjodidar.com/shaadi_api/getAllAcceptedList.php',{params:{reqId:secureLocalStorage.getItem("cust_id")}}).then(response => {
         // console.log(response.data);
          setUsers({
            usersData: response.data.fetchusers,
            size: response.data.fetchusers.length
          });
          var f = users.usersData.length;
          //console.log(users);
        }).catch((error) => {
          console.log(error.message);
          //setGloabalError(error.message);
        })
    
      }

    useEffect(() => {
        fetchData();
      }, [])
    return (
        <>
        
            <div className="panel panel-default">

                <div className="panel-heading">Accepted Request</div>
                <div className="panel-body">
                <div id="course_main" >
                    <Request_list user_detail = {users} action="accept"/>
               
                </div>
                </div>
            </div>



        </>
    )
}
