import React, { useEffect, useContext } from 'react'
import FrontHeader from './FrontHeader'
import { NavLink ,useNavigate} from 'react-router-dom';
import { Horoscope_details } from './Horoscope_details';
import { useLocation } from 'react-router-dom';
import { Community_details } from './Community_details';
import { Basic_details } from './Basic_details';
import { Living_country_details } from './Living_country_details';
import { Personal_details } from './Personal_details';
import { UserLogin } from './UserLogin';
import { Photo_details } from './Photo_details';
import userContext from './Usercontext';
import axios from 'axios';
import secureLocalStorage from "react-secure-storage";
import { toast, ToastContainer } from 'react-toastify';
export const EditProfile_details = () => {
    const location = useLocation()
    const nav = useNavigate();
    const { user, setUser } = useContext(userContext);
    // const name = "hetali";
//    console.log("ddd123", user);
  //  console.log("context details", user)
  //  console.log(secureLocalStorage.getItem("cust_id"));

    async function fetchData() {

        await axios.get('https://perfectjodidar.com/shaadi_api/getUserDetails.php', { params: { userId: secureLocalStorage.getItem("cust_id") } }).then(response => {
           // console.log(response.data);
            // setUsers({
            //     usersData: response.data.fe}tchusers,
            //     size: response.data.fetchusers.length
            // });
            // var f = users.usersData.length;
            // console.log(users);
        }).catch((error) => {
            console.log(error.message);
            //setGloabalError(error.message);
        })

    }


    useEffect(() => {
        fetchData();
       
    }, [])


    return (
        <>
            <FrontHeader userName={user} />

            <div id="blog">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 blog">
                            <div className="col-sm-3">
                                <div className="blog_right clearfix">
                                    {/* <div className="blog_right_inner clearfix">
                                        <h3>Search</h3>
                                        <input type="text" className="form-control" placeholder="Search...">
                                    </div> */}
                                    <div className="blog_right_inner_1 clearfix">
                                        <h4><i className="fa fa-list"></i>EDIT PROFILE</h4>
                                        <p> <NavLink to="/editprofile/perosnaldetails" style={({ isActive }) => (
                                            isActive ? { color: '#f5b120' } : {})}><i className="fa fa-caret-right"></i>Personal Details</NavLink></p>
                                        <p><NavLink to="/editprofile/userlogin" style={({ isActive }) => (
                                            isActive ? { color: '#f5b120' } : {})}><i className="fa fa-caret-right"></i>User Login Details</NavLink></p>
                                        <p><NavLink to="/editprofile/country" style={({ isActive }) => (
                                            isActive ? { color: '#f5b120' } : {})}><i className="fa fa-caret-right"></i>Living Country Details</NavLink></p>
                                        <p><NavLink to="/editprofile/basic" style={({ isActive }) => (
                                            isActive ? { color: '#f5b120' } : {})}><i className="fa fa-caret-right"></i>Basic Details</NavLink></p>
                                        <p><NavLink to="/editprofile/community" style={({ isActive }) => (
                                            isActive ? { color: '#f5b120' } : {})}><i className="fa fa-caret-right"></i>Community Details</NavLink></p>
                                        <p><NavLink to="/editprofile/horo" style={({ isActive }) => (
                                            isActive ? { color: '#f5b120' } : {})}><i className="fa fa-caret-right"></i>Horoscope Details</NavLink></p>
                                        <p><NavLink to="/editprofile/photo" style={({ isActive }) => (
                                            isActive ? { color: '#f5b120' } : {})}><i className="fa fa-caret-right"></i>Photo Details</NavLink></p>
                                        {/* <p><a href="#"><i className="fa fa-caret-right"></i>Promotional</a></p> */}
                                    </div>

                                </div>
                            </div>

                            <div className="col-sm-9">
                                <div className="blog_left clearfix" style={{ marginTop: "40px" }}>
                                    {/* <div className="clearfix"><a href="#"><img src="img/60.jpg" width="100%"/></a></div>
                                    <h3><a href="#">Sed cursus ante dapibus diam</a></h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio.Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum.Praesent mauris. Fusce nec tellus sed augue semper porta. Mauris massa. Vestibulum lacinia arcu eget nulla.className aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Vestibulum lacinia arcu eget nulla.className aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitursodales ligula in libero.</p>
                                    <p> Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer nec odio.Praesent libero. Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum.<a href="#">Praesent mauris</a>. Fusce nec tellus sed augue semper porta. Mauris massa. Vestibulum <a href="#">lacinia arcu</a> eget nulla.className aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitursodales ligula in libero.Sed dignissim lacinia nunc.</p>
                                    <p>  Sed cursus ante dapibus diam. Sed nisi. Nulla quis sem at nibh elementum imperdiet. Duis sagittis ipsum.<a href="#">Praesent mauris</a>. Fusce nec tellus sed augue semper porta. Mauris massa. Vestibulum <a href="#">lacinia arcu</a> eget nulla.className aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitursodales ligula in libero.Sed dignissim lacinia nunc. Vestibulum <a href="#">lacinia arcu</a> eget nulla.className aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Curabitursodales ligula in libero.Sed dignissim lacinia nunc</p>
                                    <div className="blog_left_inner clearfix">
                                        <a href="#" data-toggle="tooltip" title="Writer"><i className="fa fa-user"></i>NEW USER</a>
                                        <a href="#" data-toggle="tooltip" title="Released Date"><i className="fa fa-calendar"></i> 17 MAY 2017</a>
                                    </div> */}
                                    {/* dddd{secureLocalStorage.getItem("cust_id")} */}
                                    {
                                        location.pathname === "/editprofile/horo" ?
                                            <Horoscope_details users={user} />
                                            : ""
                                    }
                                    {
                                        location.pathname === "/editprofile/community" ?
                                            <Community_details users={user} />
                                            : ""
                                    }
                                    {
                                        location.pathname === "/editprofile/basic" ?
                                            <Basic_details />
                                            : ""
                                    }
                                    {
                                        location.pathname === "/editprofile/country" ?
                                            <Living_country_details />
                                            : ""
                                    }
                                    {
                                        location.pathname === "/editprofile/perosnaldetails" ?
                                            <Personal_details />
                                            : ""
                                    }
                                    {
                                        location.pathname === "/editprofile/userlogin" ?
                                            <UserLogin />
                                            : ""
                                    }
                                    {
                                        location.pathname === "/editprofile/photo" ?
                                            <Photo_details />
                                            : ""
                                    }

                                    {/* {location.pathname} */}
                                    {/* hetali{process.env.REACT_APP_API_LOCATION} */}
                                    {/* {user[0].id} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
