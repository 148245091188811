import React, { useEffect, useState } from 'react'
import "../css/bootstrap.min.css";
import "../css/style.css";
import "../css/element.css";
import { NavLink } from 'react-router-dom';

import img from "../img/72.jpg";
import img75 from "../img/75.png";
import img76 from "../img/76.png";
import img77 from "../img/77.png";

export const Header = () => {
    const [scroll, setScroll] = useState("");
    const content1 = {
        position: "absolute",
        background: "rgba(0, 0, 0, 0.5)",
        color: "#f1f1f1",
        width: "100%",
        minHeight: "800px",
        padding: "20px"

    }
    useEffect(() => {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 50) {
                setScroll("navbar-shrink");
            } else {
                setScroll("");
            }
        });
    }, [])

    return (
        <>
            <div id="header">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <nav className={`navbar navbar-default navbar-fixed-top ${scroll}`} role="navigation">
                                <div className="navbar-header">
                                    <button type="button" className="navbar-toggle" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1">
                                        <span className="sr-only">Toggle navigation</span>
                                        <span className="icon-bar"></span>
                                        <span className="icon-bar"></span>
                                        <span className="icon-bar"></span>
                                    </button>
                                    <a className="navbar-brand" href="index.html"><span style={{ background: "rgba(27, 180, 185, 0.90)", padding: "10px" }}>Perfect</span><span style={{ background: "#ea9215", padding: "10px" }}> Jodidar</span> </a>
                                </div>
                                <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
                                    <ul className="nav navbar-nav navbar-right">
                                        <li >
                                            {/* <a href="index.html">Home</a> */}
                                            <NavLink to="/" >Home</NavLink>
                                        </li>
                                        <li>
                                            {/* <a href="blog.html">Register Free</a> */}
                                            <NavLink to="/register" >Register Free</NavLink>
                                        </li>
                                        <li>
                                            {/* <a href="detail.html">Login</a> */}
                                            {/* {{isActive}} */}
                                            <NavLink to="/login" >Login</NavLink>
                                        </li>
                                        {/* <li className="">Home</li>
                                        <li>Register Free</li>
                                        <li>Login</li> */}
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ position: "relative", margin: " 0 auto", minheight: "800px" }}>
                <div style={{ background: "url(" + img + ") top center", width: "100%", minHeight: "800px" }} >

                    <div style={content1}>
                    </div>


                    <div className={`main-text col-md-12 text-center`}>
                        <h1>Though Soulmates Aren’t Looking For You,<br />
                            They Will Find You .</h1>
                        <div className="col-md-3 col-md-offset-3"> <h5>
                            {/* <a >Register FREE</a> */}
                            <NavLink to="/register" >Register Free</NavLink>
                        </h5></div>
                        <div className="col-md-2"><h5>
                            {/* <a  > Log In</a> */}
                            <NavLink to="/login" >Login</NavLink>

                        </h5></div>
                    </div>
                </div>
            </div>

            {/* third section */}

            <div id="posts">
                <div className="container">
                    <div className="row">
                        <div className="courses clearfix text-center">
                            <h3>Find Your Perfect Jodidar</h3>

                        </div>
                        <div className="posts clearfix">
                            <div className="col-sm-4">
                                <div className="posts_inner">
                                    <div className="card-image">
                                        <a href="blog.html"><img src={img75} width="100%" height="250px" /></a>


                                    </div>
                                    <div className="posts_inner_1">
                                        <h4><a href="blog.html">Register Now</a></h4>
                                        <p>Create a free account and publish your Matrimony Connect profile.
                                        </p>
                                        <h3>
                                            <NavLink to="/register" >Register Free</NavLink>
                                            {/* <a href="blog.html">Register</a> */}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="posts_inner">
                                    <div className="card-image"><a href="blog.html"><img src={img77} width="100%" height="250px" /></a></div>
                                    <div className="posts_inner_1">
                                        <h4><a href="blog.html">Connect</a></h4>

                                        <p>Choose and connect with compatible matches.</p>
                                        <h3>
                                            <NavLink to="/login" >Login</NavLink>
                                            {/* <a href="blog.html">Login</a> */}
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="posts_inner">
                                    <div className="card-image"><a href="blog.html"><img src={img76} width="100%" height="250px" /></a></div>
                                    <div className="posts_inner_1">
                                        <h4><a href="blog.html">Interact</a></h4>

                                        <p>Start a Conversation by Subscribing as a Premium Member.</p>
                                        <h3>
                                            {/* <a href="blog.html">Premium Member Register</a> */}
                                            <NavLink to="/profile/premember" >Premium Member Register</NavLink>
                                        </h3>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Fouth section */}
            <div id="started">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="courses clearfix text-center">
                                <h3> Perfect&nbsp;Jodidar</h3>
                                <p>Create a free account,Choose and connect with compatible matches,Start a Conversation by Subscribing as a Premium Member.</p>
                            </div>
                            <div className="started text-center">
                                <h5>
                                    <NavLink to="/register" >MAKE A FREE ACCOUNT</NavLink>
                                    {/* <a href="blog.html">MAKE A FREE ACCOUNT</a> */}
                                </h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Footer Section */}
            <section id="footer">
                <div className="container">
                    <div className="row">
                        <div className="footer clearfix">
                            <div className="col-sm-4">
                                <div className="footer_2">
                                    <h4>Perfect Jodidar </h4>
                                    {/* <p>
                                    
                                        <NavLink to="/Login" >Login</NavLink>
                                    </p>
                                    <p>
                                    <NavLink to="/register" >Register</NavLink>
                                    </p>
                                    <p><a href="blog.html">Contact Us</a></p>
                                      */}
                                    <p>
                                        <div>
                                            <NavLink to="/Login" >Login</NavLink>
                                        </div>
                                    </p>
                                    <p>
                                        <div>
                                            <NavLink to="/register" >Register</NavLink>
                                        </div>
                                    </p>

                                    <p>
                                        <div>
                                            <NavLink to="/aboutus" >Contact Us</NavLink>
                                        </div>
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="footer_2">
                                    <h4>Privacy & You</h4>
                                    <p>
                                        <div><NavLink to="/aboutus">About Us</NavLink></div>
                                    </p>
                                    <p>
                                        <div><NavLink to="/privacy">Privacy Support</NavLink></div>
                                    </p>
                                    <p>
                                        <div><NavLink to="/Terms_and_condition" >Terms & Privacy</NavLink></div>
                                    </p>

                                    {/* <p><a href="blog.html">Customer Support</a></p> */}
                                    {/* <p><a href="blog.html">Conditions</a></p>
                                    <p><a href="blog.html">Our Designers</a></p> */}
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="footer_2">
                                    <h4>Contact Us</h4>
                                    {/* <p>Parle Point,<br /> Surat, Gujarat-India</p>
                                    <p>+91 9104596713</p>
                                    <p>E-mail: perfectjodidar.info@gmail.com</p> */}
                                    <p><div>Parle Point,<br /> Surat, Gujarat-India</div></p>
                                    <p><div>+91 9104596713</div></p>
                                    <p><div>E-mail: perfectjodidar.info@gmail.com</div></p>
                                </div>
                            </div>
                        </div>
                        <div className="footer_bottom clearfix text-center">
                            <p> © 2023 www.perfectjodidar.com. All Rights Reserved </p>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
