import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { PageHeader } from './PageHeader';
import avatar from "../img/add_img.png";
// import $ from 'jquery';
import 'bootstrap-toggle/css/bootstrap-toggle.min.css';
import 'bootstrap-toggle/js/bootstrap-toggle.js';
import "react-datepicker/dist/react-datepicker.css";
import { Number, randomStrNum } from '../functions/GenrateNumber';
import { useForm } from 'react-hook-form'
import { PreLoader } from './PreLoader';
import { Navigate, useNavigate, NavLink } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import secureLocalStorage from 'react-secure-storage';
export const Register = () => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [loading, setLoading] = useState(false);
    const [disableb, setDisableb] = useState(false);
    const nav = useNavigate();
    const disabledProoerty = {
        backgroundColor: "#abb7b8",
        cursor: "not-allowed",
        pointerEvents: "none",
        opacity: ".65",
        filter: "alpha(opacity=65)",
        boxShadow: "none"

    }
    const onSubmit = async (data) => {
        var saveRandom = randomStrNum(10);
        setLoading(true);
        setDisableb(true)
        // console.log(data)
        // console.log("hello check data",data);
        // console.log("hello check data 123",userInfo);
        // if(profile){
        //        console.log("Yes having image");
        let fd = new FormData();
        //      console.log(profile);
        fd.append("image", profile);
        fd.append("random_id", saveRandom);
        fd.append("last_name", userInfo.lastname);
        fd.append("first_name", data.firstname);

        fd.append("gender", !data.gender ? '' : data.gender)
        fd.append("city_id", !userInfo.cityId ? '' : userInfo.city)
        fd.append("state_id", !userInfo.stateId ? '' : userInfo.stateId)
        fd.append("country_id", !userInfo.countryId ? '' : userInfo.countryId)
        fd.append("pkg", !userInfo.pkg ? '' : userInfo.pkg)
        fd.append("profilefor", !userInfo.profilefor ? '' : userInfo.profilefor)
        fd.append("education", !userInfo.education ? '' : userInfo.education)
        fd.append("employee_in", !userInfo.employee_in ? '' : userInfo.employee_in)
        fd.append("job_profile", !userInfo.jobprofile ? '' : userInfo.jobprofile)

        //     //Second table

        fd.append("uname", !data.uname ? '' : data.uname)
        fd.append("password", !data.password ? '' : data.password)
        fd.append("mstatus", !userInfo.mstatus ? '' : userInfo.mstatus)
        // fd.append("number", !userInfo.number ? '' : userInfo.numbercode + "-" + userInfo.number)
        fd.append("number", !data.number ? '' : data.numbercode + "-" + data.number)
        // fd.append("email_address", !userInfo.email ? '' : userInfo.email)
        fd.append("email_address", !data.email ? '' : data.email)
        fd.append("motherton", !userInfo.motherton ? '' : userInfo.motherton)
        fd.append("eating", !userInfo.eating ? '' : userInfo.eating)
        fd.append("religion", !userInfo.religion ? '' : userInfo.religion)
        fd.append("cast", !userInfo.cast ? '' : userInfo.cast)
        fd.append("smoke", !userInfo.smoke ? '' : userInfo.smoke)
        fd.append("drink", !userInfo.drink ? '' : userInfo.drink)
        fd.append("manglik", !userInfo.manglik ? '' : userInfo.manglik)
        fd.append("about", !userInfo.about ? '' : userInfo.about)

        //     //third table
        fd.append("hours", !userInfo.hours ? '' : userInfo.hours)
        fd.append("mins", !userInfo.min ? '' : userInfo.min)
        fd.append("sec", !userInfo.sec ? '' : userInfo.sec)
        // fd.append("birthyear", !userInfo.birthyear ? '' : userInfo.birthyear)
        // fd.append("day", !userInfo.day ? '' : userInfo.day)
        // fd.append("month", !userInfo.month ? '' : userInfo.month)
        fd.append("birthyear", !data.birthyear ? '' : data.birthyear)
        fd.append("day", !data.day ? '' : data.day)
        fd.append("month", !data.month ? '' : data.month)

          await axios.post(`https://perfectjodidar.com/shaadi_api/insert_user.php`,
//        await axios.post(`http://localhost/shaadi_api/insert_user.php`,
            fd
        ).then(response => {
            if (response.data.success) {
                setLoading(false);
                toast.success('Registered Successfully!', { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });

                setTimeout(() => {
                    nav('/login');
                }, 2000);
            } else {
                setLoading(false);

                setGloabalError(response.data.msg);
                toast.error(response.data.msg, { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });
            }
        }
        ).catch((error) => {
            setLoading(false);

            setGloabalError(error.message);
            toast.error(error.message, { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });

        });









        //        console.log(profile);

        // await axios.post(`https://perfectjodidar.com/shaadi_api/file_upload.php`,
        //     fd
        // ).then(res => {
        //     console.log(res);
        // }
        // ).catch((error) => {
        //     console.log(error);
        //     //  return error;
        // });

        // }


        // axios.post(`https://perfectjodidar.com/shaadi_api/insert_user.php`, {
        //     fd, image: profile,
        // first_name: data.firstname,
        // last_name: userInfo.lastname,
        // gender: !userInfo.optradio ? '' : userInfo.optradio,
        // city_id: !userInfo.cityId ? '' : userInfo.cityId,
        // state_id: !userInfo.stateId ? '' : userInfo.stateId,
        // country_id: !userInfo.countryId ? '' : userInfo.countryId,
        // pkg: !userInfo.pkg ? '' : userInfo.pkg,
        // profilefor: !userInfo.profilefor ? '' : userInfo.profilefor,
        // education: !userInfo.education ? '' : userInfo.education,
        // employee_in: !userInfo.employee_in ? '' : userInfo.employee_in,
        // job_profile: !userInfo.jobprofile ? '' : userInfo.jobprofile,

        //     //Second table

        //     uname: userInfo.uname,
        //     password: userInfo.password,
        //     mstatus: !userInfo.mstatus ? '' : userInfo.mstatus,
        //     number: !userInfo.number ? '' : userInfo.numbercode + "-" + userInfo.number,
        //     email_address: !userInfo.email ? '' : userInfo.email,
        //     motherton: !userInfo.motherton ? '' : userInfo.motherton,
        //     eating: !userInfo.eating ? '' : userInfo.eating,
        //     religion: !userInfo.religion ? '' : userInfo.religion,
        //     cast: !userInfo.cast ? '' : userInfo.cast,
        //     smoke: !userInfo.smoke ? '' : userInfo.smoke,
        //     drink: !userInfo.drink ? '' : userInfo.drink,
        //     manglik: !userInfo.manglik ? '' : userInfo.manglik,


        //     //third table
        //     hours: !userInfo.hours ? '' : userInfo.hours,
        //     mins: !userInfo.min ? '' : userInfo.min,
        //     sec: !userInfo.sec ? '' : userInfo.sec,
        //     birthyear: !userInfo.birthyear ? '' : userInfo.birthyear,
        //     day: !userInfo.day ? '' : userInfo.day,
        //     month: !userInfo.month ? '' : userInfo.month

        // }).then(response => {
        //     console.log(response);
        //     console.log()
        //     if (response.data.success) {
        //         toast.success('Registered Successfully!', { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });
        //         console.log("rewrite to login");

        //         setTimeout(() => {
        //             nav('/login');
        //         }, 2000);
        //     } else {
        //         setGloabalError(response.data.msg);
        //         toast.error(response.data.msg, { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });
        //     }
        // }).catch(error => {
        //     toast.error(error.message, { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });
        //     console.log(error);
        // })

    }
    let [checked, setChecked] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [preview, setpreview] = useState();
    const [globalError, setGloabalError] = useState();
    const [country, setCountry] = useState({
        countryId: '',
        stateId: '',
        countryData: [],
        stateData: [],
        cityData: []
    });
    const [globalData, setGlobalData] = useState({
        status: [],
        degree: [],
        occupa: [],
        profilefor: [],
        rel: []
    });
    const [profile, setProfile] = useState();
    const label = { fontSize: "16px" };

    const [userInfo, setuserInfo] = useState({});

    const oncheck = (e) => {

        setuserInfo({
            ...userInfo,
            [e.target.name]: e.target.checked
        });
    }

    const onChangeValue = (e) => {

        setuserInfo({
            ...userInfo,
            [e.target.name]: e.target.value
        });

    }

    const handleSelect = (e) => {
        setuserInfo({
            ...userInfo,
            [e.target.name]: e.target.value
        });
    }

    useEffect(() => {

        if (secureLocalStorage.getItem("cust_id")) {
            //  nav("/front");
        }
        axios.get('https://perfectjodidar.com/shaadi_api/get.php').then(response => {
            // console.log(response.data);
            setCountry({
                // ...country,
                // countryId:'',

                countryData: response.data.fetchcountry
            });
            setGlobalData({
                status: response.data.status,
                profilefor: response.data.profilefor,
                degree: response.data.degree,
                occupa: response.data.occu,
                rel: response.data.rel
            });
        }).catch((error) => {
            console.log(error.message);
            setGloabalError(error.message);
        })

        //generateNumber();
    }, []);


    const fileSelect = (event) => {
        setProfile(event.target.files[0]);
        var file = event.target.files[0];
        const url = URL.createObjectURL(file);
        setpreview(url);
    }


    const fileUpload = () => {
        let fd = new FormData();

        fd.append("image", profile);
        //console.log(fd);

        //         var formData1 = new FormData();
        // formData1.append('key1', 'value1');
        // formData1.append('key2', 'value2');

        // // Display the key/value pairs
        for (var pair of fd.entries()) {
            console.log(pair[0] + ', ' + pair[1]);
        }


        axios.post('https://perfectjodidar.com/shaadi_api/file_upload.php', fd
        ).then(res => {
            // console.log(res);
        }
        ).catch((error) => {
            console.log(error);
            //  return error;
        });
    }

    const ChangeteState = (e) => {
        setCountry({
            ...country,
            countryId: e.target.value,
        });

        setuserInfo({
            ...userInfo,
            countryId: e.target.value
        });
        axios.get('https://perfectjodidar.com/shaadi_api/getState.php?id=' + e.target.value).then(response => {
            setCountry({
                ...country,
                stateData: response.data.fetchstate
            });
        });
    }



    const ChangeCity = (e) => {
        setCountry({
            ...country,
            stateId: e.target.value
        });


        axios.get('https://perfectjodidar.com/shaadi_api/getCity.php?id=' + e.target.value).then(response => {
            //  console.log(response.data);
            var f = response.data.fetchcity[0];
            // console.log(f.id);
            // console.log("inside State city", (response.data.fetchcity[0]).id);

            setuserInfo({
                ...userInfo,
                stateId: e.target.value,
                cityId: (response.data.fetchcity[0]).id
            });
            setCountry({
                ...country,
                cityData: response.data.fetchcity
            });
        });
    }

    return (
        <>
            <PageHeader />
            <div>
                {/* <button onClick={notify}>Notify!</button> */}
                <ToastContainer />
            </div>
            {loading ?
                <PreLoader />
                : ""}
            <div className="col-md-6 col-md-offset-3 text-center" style={{ marginTop: "100px" }}>
                <h1>Registration</h1>
                <hr className="star-primary" />
                <p className="" style={{ fontWeight: "bold", color: "red", fontSize: "16px", padding: "10px" }}> {globalError ? <p>Sorry, {globalError}</p> : ""}</p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div id="" style={{ backgroundColor: "#f1f1f1" /* background: "rgba(237, 237, 237, 1)", minHeight: "800px"*/ }}>
                    <div className="container" id="registration">
                        <div className="row" style={{ marginTop: "80px" }}>
                            <div className="col-md-6">

                                <div className="panel panel-default">
                                    <div className="panel-heading" >Person Details</div>
                                    <div className="panel-body">
                                        <div className="form-group">
                                            <label style={label} >Profile For:</label>
                                            <select className="form-control" name="profilefor" onChange={onChangeValue} >
                                                <option>--Select Profile For--</option>
                                                {
                                                    globalData.profilefor ? globalData.profilefor.map((e, key) => {
                                                        return <option key={key} value={e.id}>{e.name}</option>
                                                    }) : ""
                                                }

                                            </select>
                                            {/* <p className="error">{errors.profilefor && <p>Plesasss</p>}</p> */}
                                        </div>

                                        <div className="form-group">
                                            <label className={label}>First Name:*</label>
                                            <input type="text" placeholder='Enter your first name' name="firstname" onChange={onChangeValue} className="form-control"  {...register("firstname", { required: true, minLength: 2 })} />
                                            {/* <input type="text" name="firstname" {...register("firstname", { required: true, minLength: 5 })} /> */}
                                            <p className="error">{errors.firstname && errors.firstname.type === "required" && <p>Please enter first name</p>}</p>
                                            <p className="error">{errors.firstname && errors.firstname.type === "minLength" && <p>Please enter Atleast 2 Charater name</p>}</p>

                                        </div>


                                        <div className="form-group">
                                            <label className={label}>Last Name:</label>
                                            <input type="text" placeholder='Enter your last name' name="lastname" onChange={onChangeValue} className="form-control" />
                                        </div>
                                        <div className="form-group">
                                            <label className={label}>Marital Status:</label>
                                            <select className="form-control" name="mstatus" value={globalData?.status.id} onChange={onChangeValue}>
                                                <option>--Select Marital Status--</option>

                                                {
                                                    globalData.status ? globalData.status.map((e, key) => {
                                                        return <option key={key} value={e.id}>{e.name}</option>;
                                                    }) : ""
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label className={label}>Contact No:*</label>
                                            <div className="form-inline">
                                                <input type="number" className="form-control" style={{ width: "75px" }} placeholder='code' name="numbercode" onChange={onChangeValue} {...register("numbercode", { required: true })} />
                                                <input type="number" className="form-control" placeholder='Enter WhatsApp Number' name="number" onChange={onChangeValue} {...register("number", { required: true, minLength: 10 })} />
                                                <p className="error">{errors.numbercode && errors.numbercode.type === "required" && <p>Please enter number code</p>}</p>
                                                <p className="error">{errors.number && errors.number.type === "minLength" && <p>Please enter proper number</p>}</p>
                                            </div>

                                        </div>
                                        <div className="form-group">
                                            <label className={label}>Email Address:*</label>
                                            {/* <input type="text" className="form-control" placeholder='Enter Email address' name="email" onChange={onChangeValue} {...register("email", { required: true, pattern: /^\S+@\S+$/i})} /> */}
                                            <input type="text" className="form-control" placeholder='Enter Email address' name="email" onChange={onChangeValue} {...register("email", { required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i })} />

                                            <p className="error">{errors.email && errors.email.type === "required" && <p>Please enter email address</p>}</p>
                                            <p className="error">{errors.email && errors.email.type === "pattern" && <p>Please enter proper email address</p>}</p>
                                        </div>
                                        <div className="form-group">
                                            <label className={label}>Mother tonque:</label>
                                            <input type="text" className="form-control" placeholder='Enter Mother Tonque' name="motherton" onChange={onChangeValue} />
                                        </div>
                                        <div className="form-group">
                                            <label className={label}>Eating Habits:</label>
                                            <select className="form-control" name="eating" onChange={onChangeValue}>
                                                <option>--Select Eating Habits--</option>
                                                <option value="Vegetarian" key="1">Vegetarian</option>
                                                <option value="Non Vegitarian" key="2">Non Vegitarian</option>
                                                <option value="Eggetarian" key="3">Eggetarian</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label className={label}>Gender:*</label>
                                            <div className="">
                                                <label className="radio-inline"><input type="radio" name="optradio" onChange={onChangeValue} value="female" {...register("gender", { required: true })} />Female</label>
                                                {/* </div> */}
                                                {/* <div className="radio"> */}
                                                <label className="radio-inline"><input type="radio" name="optradio" onChange={onChangeValue} value="male" {...register("gender", { required: true })} />Male</label>

                                                {/* <input type="text" placeholder='Enter your first name' name="firstname" onChange={onChangeValue} className="form-control"  {...register("firstname", { required: true, minLength: 2 })} /> */}
                                                {/* <input type="text" name="firstname" {...register("firstname", { required: true, minLength: 5 })} /> */}
                                                <p className="error">{errors.gender && errors.gender.type === "required" && <p>Please select gender</p>}</p>
                                                {/* <p className="error">{errors.firstname && errors.firstname.type === "minLength" && <p>Please enter Atleast 2 Charater name</p>}</p> */}





                                            </div>
                                        </div>


                                        <div className="form-group">
                                            <label className={label}>Tell me about yourself:</label>
                                            {/* <input type="textarea" rows="8" name="tellmeabout" onChange={onChangeValue} className="form-control" /> */}
                                            <textarea class="form-control" rows="3" onChange={onChangeValue} name="about"></textarea>
                                        </div>
                                    </div>
                                </div>

                                <div className="panel panel-default">

                                    <div className="panel-heading">Basic Details</div>
                                    <div className="panel-body">

                                        <div className="form-group">
                                            <label >Education:</label>
                                            <select className="form-control" name="education" value={globalData.degree.id} onChange={onChangeValue}>
                                                <option>--Select Higest Degree--</option>

                                                {
                                                    globalData.degree.map((e, key) => {
                                                        return <option key={key} value={e.id}>{e.name}</option>;
                                                    })
                                                }
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label >Job profile:</label>
                                            <select className="form-control" name="jobprofile" value={globalData.occupa.id} onChange={onChangeValue}>
                                                <option>--Select Job Profile--</option>

                                                {
                                                    globalData.occupa ? globalData.occupa.map((e, key) => {
                                                        return <option key={key} value={e.id}>{e.name}</option>;
                                                    }) : ""
                                                }
                                            </select>

                                        </div>
                                        <div className="form-group">
                                            <label >Package:</label>
                                            <input type="number" name="pkg" className="form-control" onChange={onChangeValue} />
                                        </div>
                                        <div className="form-group">

                                            {/* Mangalik: <input type="checkbox"  data-size="lg" onCick={oncheck}/> */}
                                            <label>Smoke:</label> <input type="checkbox" name="smoke" class="lgcheckbox" onClick={oncheck} /><br />
                                            <label>Drink: </label><input type="checkbox" name="drink" class="lgcheckbox" onClick={oncheck} />

                                        </div>
                                        <div className="form-group">
                                            <label >Employee In:</label>
                                            <select className="form-control" name="employee_in" id="employee_id" onChange={onChangeValue}>
                                                <option>Select</option>

                                                <option value="Government">Government</option>
                                                <option value="Private">Private</option>
                                                <option value="Business">Business</option>
                                                <option value="Defence">Defence</option>
                                                <option value="Not Working">Not Working</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="col-md-6">
                                <div className="panel panel-default">

                                    <div className="panel-heading">Login Details</div>
                                    <div className="panel-body">
                                        {/* <form> */}
                                        <div className="form-group">
                                            <label >User Name: *</label>
                                            <input type="text" className="form-control" name="uname" onChange={onChangeValue} placeholder="Enter username" {...register("uname", { required: true })}  />

                                            <p className="error">{errors.uname && errors.uname.type === "required" && <p>Please Enter Username</p>}</p>

                                        </div>


                                        <div className="form-group">
                                            <label >Password: *</label>
                                            <input type="password" className="form-control" name="password" onChange={onChangeValue}  {...register("password", { required: true })} />
                                            <p className="error">{errors.password && errors.password.type === "required" && <p>Please Enter Password</p>}</p>

                                        </div>

                                        <div className="form-group">
                                            <label> Profile Image </label>
                                            <label>
                                                <img src={!preview ? avatar : ""} />
                                                <input type="file" onChange={fileSelect} name="myfile" style={{ display: "none" }} />
                                                {
                                                    preview ?
                                                        <img src={preview} className="img-circle" style={{ height: "170px", width: "170px", padding: "10px" }} />
                                                        : ""
                                                }
                                            </label>
                                        </div>
                                        {/* <button type='submit'>Submit</button> */}
                                        {/* </form> */}
                                    </div>
                                </div>
                                <div className="panel panel-default">

                                    <div className="panel-heading">Living  Details</div>
                                    <div className="panel-body">

                                        <div className="form-group">
                                            <label >Country:</label>
                                            <select className="form-control" name="country" value={country.countryId} onChange={(e) => ChangeteState(e)}>
                                                {/* <option>Select Country</option> */}
                                                {/* selected={loginDetails?no == loginDetails.birth_date?'selected':'':''} 
                                                selected={e.name == "India"?'selected':''}
                                                */}
                                                {
                                                    country.countryData.map((e, key) => {
                                                        return <option key={key} value={e.id} >{e.name}</option>;
                                                    })
                                                }
                                            </select>
                                            {/* <input type="text" placeholder='Enter your first name' name="firstname" onChange={onChangeValue} className="form-control"  {...register("firstname", { required: true, minLength: 2 })} /> */}
                                            {/* <input type="text" name="firstname" {...register("firstname", { required: true, minLength: 5 })} /> */}
                                            {/* <p className="error">{errors.country && errors.country.type === "required" && <p>Please Select Country</p>}</p> */}

                                        </div>
                                        <div className="form-group">
                                            <label >State:</label>
                                            <select className="form-control slct" name="state" value={country.stateId} onChange={(e) => ChangeCity(e)} >

                                                {country.stateData ? country.stateData.map((e, key) => {
                                                    return <option key={key} value={e.id}>{e.name}</option>;
                                                }) : ''}
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label >City:</label>
                                            <select className="form-control slct" name="city" defaultValue={country.cityData} onChange={onChangeValue} >

                                                {country.cityData ? country.cityData.map((e, key) => {
                                                    return <option key={key} value={e.id}>{e.name}</option>;
                                                }) : ''}
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div className="panel panel-default">

                                    <div className="panel-heading">Community Details</div>
                                    <div className="panel-body">
                                        <div className="form-group">
                                            <label >Religion For:</label>
                                            <select className="form-control" name="religion" onChange={onChangeValue}>
                                                <option>--Select Religion--</option>
                                                {
                                                    globalData.rel ? globalData.rel.map((e, key) => {
                                                        return <option key={key} value={e.id}>{e.name}</option>
                                                    }) : ""
                                                }

                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label >Cast:</label>
                                            <input type="text" className="form-control" name="cast" onChange={onChangeValue} />
                                        </div>
                                    </div>
                                </div>
                                <div className="panel panel-default">

                                    <div className="panel-heading">Horoscope Details</div>
                                    <div className="panel-body">
                                        <div className="form-group">
                                            <label >Birth Date:*</label>
                                            <div className="form-inline">

                                                <select name="day" id="day" className="form-control" onChange={onChangeValue} {...register("day", { required: true })}>
                                                    <option value="" >--Birth Date--</option>
                                                    {
                                                        Number(31).map(no => <option key={no} value={no}>{no}</option>)
                                                    }
                                                </select>
                                                <select name="month" id="month" className="form-control" onChange={onChangeValue} {...register("month", { required: true })}>
                                                    <option value="" >--Birth Month--</option>
                                                    <option value="01" label="Jan">Jan</option>
                                                    <option value="02" label="Feb">Feb</option>
                                                    <option value="03" label="Mar">Mar</option>
                                                    <option value="04" label="Apr">Apr</option>
                                                    <option value="05" label="May">May</option>
                                                    <option value="06" label="Jun">Jun</option>
                                                    <option value="07" label="Jul">Jul</option>
                                                    <option value="08" label="Aug">Aug</option>
                                                    <option value="09" label="Sep">Sep</option>
                                                    <option value="10" label="Oct">Oct</option>
                                                    <option value="11" label="Nov">Nov</option>
                                                    <option value="12" label="Dec">Dec</option>
                                                </select>
                                                <input type="text" name="birthyear" className="form-control" onChange={onChangeValue} {...register("birthyear", { required: true })} />
                                                <p className="error">{errors.day && errors.day.type === "required" && <p>Please enter birth date</p>}</p>
                                                <p className="error">{errors.month && errors.month.type === "required" && <p>Please enter birth month</p>}</p>
                                                <p className="error">{errors.birthyear && errors.birthyear.type === "required" && <p>Please enter birth year</p>}</p>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label >Birth Time:</label>
                                            <div className="form-inline">
                                                <select name="hours" id="hours" className="form-control" onChange={onChangeValue}>
                                                    <option value="" >--hours--</option>
                                                    <option value="0" label="0">0</option>
                                                    {
                                                        Number(23).map(no => <option key={no} value={no}>{no}</option>)
                                                    }

                                                </select>
                                                <select name="min" id="min" className="form-control" onChange={onChangeValue}>
                                                    <option value="" >--min--</option>
                                                    <option value="0" label="0">0</option>
                                                    {
                                                        Number(59).map(no => <option key={no} value={no}>{no}</option>)
                                                    }

                                                </select>
                                                <select name="sec" id="sec" className="form-control" onChange={onChangeValue}>
                                                    <option value="" >--sec--</option>
                                                    <option value="0" label="0">0</option>
                                                    {
                                                        Number(59).map(no => <option key={no} value={no}>{no}</option>)
                                                    }


                                                </select>
                                            </div>
                                        </div>
                                        <div className="form-group">

                                            <label>Mangalik: </label>
                                            {/* <label class="switch"> */}
                                            <input type="checkbox" class="lgcheckbox" name="manglik" onClick={oncheck} />
                                            {/* <span class="slider"></span> */}
                                            {/* </label> */}
                                            {/* <input type="checkbox" data-toggle="toggle" data-size="lg" name="mangal" 
                                            checked={checked}
                                            
                                            onChange={e => onChangeValue(e.target.checked)}
                                             /> */}
                                            {/*
                                             onChange={(e) => {
                                                onChangeValue(e)
                                            }}
                                             Smoke: <input type="checkbox"  data-toggle="toggle" data-size="lg" /> */}
                                            {/* Drink: <input type="checkbox"  data-toggle="toggle" data-size="lg" /> */}

                                        </div>
                                    </div>
                                </div>
                                <div className="panel panel-default">

                                    <div className="panel-heading">Terms And Conditions</div>
                                    <div className="panel-body">
                                        <div className="form-group">

                                            <input type="checkbox" name="tnc" onClick={oncheck} {...register("tnc", { required: true })} />

                                            <NavLink to="/Terms_and_condition" target="_blank"> &nbsp;&nbsp;Agree & Accept Terms and Conditions</NavLink>
                                            <p className="error">{errors.tnc && errors.tnc.type === "required" && <p>Please Accept Terms And Condition</p>}</p>
                                        </div>

                                    </div>
                                </div>
                            </div>


                        </div>
                        <div className="col-md-6 col-md-offset-3" style={{ padding: "20px" }}>
                            {/* <button type="submit" class="btn btn-default btn-lg">Submit</button> */}
                            <input type="submit" name="login-submit" id="login-submit" className="form-control btn btn-reg" value="Submit" style={disableb ? disabledProoerty : null}></input>

                        </div>
                    </div>
                </div>
            </form>
            {/* <div>
                <input type="file" onChange={fileSelect} />
                <button onClick={fileUpload}>Upload</button>
                {
                    preview ?
                        <img src={preview} style={{ height: "200px", width: "200px" }} />
                        : ""
                }
            </div>



            <li className="text-center"><img className="img-circle" src={avatar} /><p>avatar-lg</p></li>
            <form><input type="text" required />
                <input type="text" required />
                <input type="text" required /><button type="submit" >Submit</button> </form>


            <label>

            </label> */}

        </>
    )
}
