import React, { useState,useEffect } from 'react'
import axios from 'axios';
import { useForm } from 'react-hook-form'
import secureLocalStorage from 'react-secure-storage';
export const Photo_details = () => {
    const [img, setImg] = useState([]);
    const [index, setIndex] = useState(0)
    
    const [imageUser, setImageUser] = useState();

    const next = () => {
        //console.log("checking data",imageUser.length);
        if (index === imageUser.length - 1) {
            setIndex(0)
        } else {
          // console.log("udbex",index);
            setIndex(index + 1)
        }
    }
    const prev = () => {
        if (index === 0) {
            setIndex(imageUser.length - 1)
        } else {
            setIndex(index - 1)
        }
    }
    const Thumbnail = ({ arr, image, index }) => {
        // if(arr){
        return (<div className="tumbnail">
            {
                arr ? arr.map((imgsrc, i) => (

                    <img
                        key={i}
                        height="50"
                        src={imgsrc}
                        onClick={() => image(i)}
                        className={index === i ? 'active' : ''}
                    />
                )) : ''
            }
        </div>)       
        // }else{
        //     return(<div>fff</div>)
        // }
    }

    // const Thumbnail = () =>{

    // }
    async function fetchData() {
        await axios.get('https://perfectjodidar.com/shaadi_api/getPhotoDetailsById.php', { params: { userId: secureLocalStorage.getItem("cust_id") } })
        .then(response => {               
           if(response.data.success){
            setImageUser(response.data.res?response.data.res:'');            
           }
        }).catch((error) => {
            console.log(error.message);            
        })
    }    
    useEffect(() => {
        fetchData();
    }, []);

    const { register, handleSubmit, defaultValues } = useForm();
    const onSubmit = async (data) => {        
        let fd = new FormData();
        for (let i = 0; i < img.length; i++) {
            fd.append("file[]", img[i]);            
        }
        fd.append("image", img);
        fd.append("user_id", secureLocalStorage.getItem("cust_id"));

        await axios.post(`https://perfectjodidar.com/shaadi_api/image_fileupload.php`,
            fd
        ).then(response => {
            //console.log(response);
            if (response.data.success) {
                //toast.success('Registered Successfully!', { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });
     
                fetchData();
            } else {
                //toast.error(response.data.msg, { hideProgressBar: true, progress: undefined, position: "top-center", autoClose: 1500 });
            }
        }
        ).catch((error) => {
            console.log(error);
        });
    }

    const fileSelect = (event) => {        
     
        setImg(event.target.files);        
    }
    return (
        <>
            <div className="panel panel-default">

                <div className="panel-heading">Horoscope Details</div>
                <div className="panel-body">
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group">
                            <label> Select Image </label>
                            <input type="file" name="myfile" onChange={fileSelect} multiple />

                        </div>
                        <div className="col-md-6 col-md-offset-3" style={{ padding: "20px" }} onChange={fileSelect}>
                            {/* <button type="submit" class="btn btn-default btn-lg">Submit</button> */}
                            <input type="submit" name="login-submit" id="login-submit" className="form-control btn btn-reg" value="Upload Photo"></input>

                        </div>
                    </form>
                    {imageUser?
                    <div className="slideshow">
                                        <img className="mainImg" src={imageUser?imageUser[index]:''} style={{maxWidth:"100%",height:"auto"}}/>
                                        <div className="actions" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                            {/* <button onClick={prev}>👈</button>
                                            <button onClick={next}>👉</button> */}
                                            <button onClick={prev}>&lt;&lt;Prev</button>
                                            <button onClick={next}>Next&gt;&gt;</button>
                                        </div>
                                        
                                        {imageUser ? <Thumbnail arr={imageUser} image={setIndex} index={index} /> :''}
                                        {/* {imageUser ? imageUser :''} */}
                                        {/* <Thumbnail arr={imageUser} image={setIndex} index={index} /> */}
                                    </div>
                     :''} 
                </div>
            </div>

        </>
    )
}
